import { FetchStatus } from '@tanstack/react-query';
import React from 'react';
import { MdAdd } from 'react-icons/md';
import { Divider } from 'rsuite';
import { checkIfIsLoading } from '../../../../../../../../utils';
import styles from './Cells.module.scss';

export const renderExtraFooter = (options: React.ReactNode[]) => {
    return function Component() {
        return (
            <div className={styles['cluster__render-extra-footer']}>
                <Divider />
                <ul>
                    {options.map((option, index) => (
                        <li key={index}>{option}</li>
                    ))}
                </ul>
            </div>
        );
    };
};

export const renderMenu = (fetchStatus: FetchStatus) => {
    return function Component(menu: React.ReactNode) {
        if (checkIfIsLoading(fetchStatus)) return <p className={styles['cluster__render-loading']}>Carregando...</p>;
        return menu;
    };
};

type RenderExtraFooterOptionsProps = {
    handleClickCleanClusterValue?: () => void;
    handleClickAddNewCluster?: () => void;
};

export const renderExtraFooterOptions = ({  handleClickAddNewCluster }: RenderExtraFooterOptionsProps) => [

    <button key="add-cluster" onClick={handleClickAddNewCluster}>
        <MdAdd />
        Adicionar novo
    </button>,
];
