import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Notification } from 'rsuite';
import { RootState } from '../../../../@types/RootState';
import { queryClient } from '../../../../App';
import { closeModal, RESET_SAVED_FILTERS, selectorGerenciadorPrecos, SET_SAVED_FILTER, setSavedFilterInputName } from '../../../../reducers/gerenciadorPrecos';
import { PriceManagerQuickActionFilter } from '../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { deleteSavedFilter, postSavedFilter, PostSavedFitersDataProps, putSavedFilter } from '../services_ts';
import { FILTERS_OPTIONS_PROPS } from './useFiltrosState';

type FILTROS_MODEL_PROPS = Record<FILTERS_OPTIONS_PROPS, string[]>;

export type SELECTED_FILTROS_DATA_PROPS = Record<'label' | 'value', string> | null;

export const useSalvarFiltros = (
    filtrosModel: FILTROS_MODEL_PROPS & Record<keyof PriceManagerQuickActionFilter, boolean | null>,
    // filtrosModel: FILTROS_MODEL_PROPS & Record<'brokenLimit' | 'changedPrices' | 'competitorPrices' | 'marginRange', boolean | null>,
) => {
    const state = useSelector(selectorGerenciadorPrecos);

    const dispatch = useDispatch();

    const data = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.filters.data.savedFilters;
    });

    const refetchSavedFilters = useCallback(async () => {
        queryClient.invalidateQueries(['ipa.filter.savedFilters']);
    }, []);

    const model: PostSavedFitersDataProps = {
        id: state.savedFilter?.data?.value ?? '',
        name: state.savedFilter?.filterName ?? '',
        screen: 'PRICE_MANAGER',
        filters: filtrosModel,
    };

    const salvarFiltros = async () => {
        postSavedFilter(model)
            .then((response) => {
                if (response.status !== 200) return;
                refetchSavedFilters().then(() => {
                    Notification.success({
                        description: 'Filtro salvo com sucesso.',
                    });
                    dispatch(closeModal({ name: 'salvarFiltros' }));
                });
            })
            .catch((err) => {
                Alert.error(err.response.data._embedded.errors[0].message);
            });
    };

    const editarFiltros = async () => {
        putSavedFilter(model)
            .then((response) => {
                if (response.status !== 201) return;
                refetchSavedFilters().then(() => {
                    Notification.success({
                        description: 'Filtro editado com sucesso.',
                    });
                    dispatch(closeModal({ name: 'salvarFiltros' }));
                });
            })
            .catch((err) => {
                Alert.error(err.response.data._embedded.errors[0].message);
            });
    };

    const excluirFiltroSalvo = async (handleLimparFiltros?: () => void) => {
        deleteSavedFilter(state.savedFilter?.data?.value ?? '')
            .then((response) => {
                if (response.status !== 200) return;
                refetchSavedFilters().then(() => {
                    handleLimparFiltros?.();

                    dispatch(RESET_SAVED_FILTERS());
                    dispatch(setSavedFilterInputName({ value: '' }));
                    dispatch(closeModal({ name: 'excluirFiltro' }));

                    Notification.success({
                        description: 'Filtro excluido com sucesso.',
                    });
                });
            })
            .catch((err) => {
                Alert.error(err.response.data._embedded.errors[0].message);
            });
    };

    useEffect(() => {
        const filtroSalvo = data?.find((item) => item.label === state.savedFilter.filterName);
        if (filtroSalvo) {
            const { label, value } = filtroSalvo;
            dispatch(
                SET_SAVED_FILTER({
                    data: {
                        filterName: label,
                        data: {
                            label,
                            value,
                            name: label,
                        },
                    },
                }),
            );
        }
    }, [data]);

    return {
        salvarFiltros,
        excluirFiltroSalvo,
        editarFiltros,
    };
};
