import { format } from 'date-fns';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, CheckPicker, DateRangePicker } from 'rsuite';
import DateRangePickerPill from '../../../../../../../components/DateRangePickerPill';
import { FilterSeparator, SelectPill } from '../../../../../../../components/Filters';
import { useDebounce } from '../../../../../../../hooks';
import { useSearchParams } from '../../../../../../../hooks/useSearchParams';
import { selectorDemandForecastFiltersArea } from '../../../../../../../reducers/previsaoDemanda';
import { dateLocale } from '../../../../../../ISA/InfoPanel/OnDemand/utils';
import { useFilters } from '../../hooks/useFilters';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { setSearchStores } from '../../../../../../../reducers/previsaoDemanda/filterArea';
import { setInputList } from '../../../../../../../reducers/previsaoDemanda/inputArea';
import { setDailyStoreSalesChartData, setOutputs, setRevenueDemandChartData } from '../../../../../../../reducers/previsaoDemanda/outputArea';
import { setScenario } from '../../../../../../../reducers/previsaoDemanda/outputSavedSimulations';
import { demandForecastGetScenarioById, getDailyStoreSalesChartById, getRevenueDemandChartById } from '../../services';
import styles from './filter.module.scss';

export const dateShortcutNextDays = [
    {
        label: 'Próximos 7 dias',
        value: [moment().toDate(), moment().add(7, 'days').toDate()],
    },
    {
        label: 'Próximos 15 dias',
        value: [moment().toDate(), moment().add(15, 'days').toDate()],
    },
    {
        label: 'Próximos 30 dias',
        value: [moment().toDate(), moment().add(30, 'days').toDate()],
    },
];

const selectOptions = [
    {
        label: 'Loja',
        value: 'STORE',
    },
    // {
    //     label: 'Cluster',
    //     value: 'CLUSTER',
    // },
];
export const FiltersArea = () => {
    const { beforeToday } = DateRangePicker;

    const params = useParams() as Record<'id', string>;
    const dispatch = useDispatch();
    const [selectMode, setSelectMode] = useState('STORE');
    const { setParam, deleteParam, getParam, clearParams } = useSearchParams();
    const filtersArea = useSelector(selectorDemandForecastFiltersArea);
    const [searchProduct, setSearchProduct] = useState(getParam('productId') || filtersArea.selectedFilters?.productId || '');

    const {
        listProducts,
        listStores,
        handleSetFilter,
        clearFilters,
        handleClearDatePicker,
        generateSelectedItemDetails,
        isListStoresLoading,
        getDemandForecastInputsValue,
        handleSetActiveFilter,
    } = useFilters();

    const getPromiseAndSetCallback = (promise: Promise<any>, id: string, cb: (p: any) => void) => {
        promise
            .then((result) => {
                cb(result);
            })
            .catch((error) => {
                cb([]);
                console.error(`Promise ${id} rejected`);
            });
    };

    const { isLoading: isListProductsLoading } = useQuery({
        queryKey: ['demand-forecast-products', searchProduct],
        retry: true,
        keepPreviousData: true,
        queryFn: () => listProducts(searchProduct),
        onSuccess: (data) => {
            generateSelectedItemDetails(data);
        },
    });

    const getAllData = async (outputData: any) => {
        const simulationFamilyId = outputData.simulation_family_id;
        const scenarioId = outputData.scenario.id;

        getPromiseAndSetCallback(getRevenueDemandChartById(simulationFamilyId), 'getRevenueDemandChartById', (data) => {
            dispatch(setRevenueDemandChartData(data));
        });

        // TODO  /profit-revenue aguardando implementação
        // getPromiseAndSetCallback(getProfitRevenueChartById(simulationFamilyId), 'getDemandForecastInputsValue', (data) => {
        //     dispatch(setProfitRevenueChartData(data));
        // });

        getPromiseAndSetCallback(getDailyStoreSalesChartById(scenarioId), 'getDailyStoreSalesChartById', (data) => {
            const chartDailyData = {
                title: 'Gráfico de sazonalidade',
                subtitle: 'Gráfico do preço simulado na venda para cada data no período selecionado',
                data: data,
            };
            dispatch(setDailyStoreSalesChartData(chartDailyData));
        });
    };

    useQuery({
        queryKey: ['demand-forecast-get-scenario', params?.id],
        retry: true,
        keepPreviousData: true,
        queryFn: () => demandForecastGetScenarioById(params?.id),
        onSuccess: async (outputData) => {
            if (outputData) {
                const { from_date, to_date, product_id, store_ids } = outputData.query;

                setSearchProduct(product_id);
                dispatch(
                    setOutputs({
                        simulation: outputData.simulations,
                        output: outputData.output,
                        query: outputData.query,
                        scenario: outputData.scenario,
                    }),
                );

                const inputs = outputData.input.map((input: any) => {
                    return {
                        value: input.new_price,
                        basePrice: input.base_price,
                        margin: input.margin,
                        newPrice: input.new_price,
                        competitiveness: input.competitiveness,
                    };
                });

                dispatch(setInputList({ inputs: inputs }));

                dispatch(
                    setScenario({
                        ...outputData.scenario,
                        scenarioName: outputData.scenario.scenario_name,
                    }),
                );

                handleSetFilter({
                    name: 'productId',
                    filterValue: product_id,
                    clearData: false,
                });

                handleDateRangePicker([new Date(`${from_date}T00:00:00.175`), new Date(`${to_date}T00:00:00.175`)], false);

                handleSetFilter({
                    name: 'storesId',
                    filterValue: store_ids,
                    clearData: false,
                });

                getAllData(outputData);
            }
        },
        enabled: !!params?.id,
    });

    const refToDatePicker = useRef<{ handleClean: () => void }>();

    const debounce = useDebounce(500);

    const getPlaceholder = () => {
        if (!selectMode) return 'Selecione uma opção';

        if (selectMode === 'STORE') return 'Selecione as lojas';

        return 'Selecione os clusters';
    };

    const handleDateRangePicker = (value: Date[], clearData = false) => {
        if (value[0] && value[1]) {
            setParam('filterDate', format(value[0], 'yyyy/MM/dd').concat('-', format(value[1], 'yyyy/MM/dd')));
            handleSetFilter({
                name: 'dateRange',
                filterValue: value,
                clearData,
            });
            handleSetActiveFilter({
                key: 'date',
                item: value,
            });
        } else if (value.length === 0) {
            deleteParam('filterDate');
        }
    };

    const onCleanAll = (clearUrlParams = true) => {
        setSelectMode('STORE');
        if (clearUrlParams) {
            refToDatePicker?.current?.handleClean();
            clearParams();
        }
        clearFilters();
        setSearchProduct('');
        listProducts();
    };

    const isCheckedAllStores =
        filtersArea.selectedFilters?.storesId.length > 0 &&
        filtersArea?.list.stores?.every((store: any) => filtersArea.selectedFilters.storesId.includes(store.store_id));

    const indeterminateStores = filtersArea.selectedFilters?.storesId?.length > 0 && !isCheckedAllStores;

    return (
        <main className={styles['filters-area-content']}>
            <div className={styles['inner-div']}>
                <div>
                    <div className={styles['filter-selection-wrapper']}>
                        <div className={styles['div-title']}>Produto</div>
                        <SelectPill
                            id="select-picker-pill"
                            placeholder="Selecione um produto"
                            name="produto"
                            filter={false}
                            value={filtersArea?.selectedFilters?.productId}
                            data={filtersArea?.list?.products}
                            onClose={() => {
                                if (!filtersArea.selectedFilters.productId) {
                                    setSearchProduct('');
                                }
                            }}
                            onSelect={(value: any, item: any) => {
                                handleSetFilter({
                                    name: 'productId',
                                    filterValue: value,
                                });
                                handleSetActiveFilter({
                                    key: 'product',
                                    item,
                                });
                                setParam('productId', value);
                            }}
                            onSearch={(e) => debounce(() => setSearchProduct(e))}
                            onClean={() => {
                                handleSetFilter({
                                    name: 'productId',
                                    filterValue: '',
                                });
                                deleteParam('productId');
                                setSearchProduct('');
                                listProducts();
                            }}
                            locale={{
                                noResultsText: isListProductsLoading ? 'Carregando produtos...' : 'Nenhum item encontrado',
                                searchPlaceholder: 'Pesquisar',
                            }}
                        />
                    </div>
                    <div className={styles['filter-selection-wrapper']}>
                        <div className={styles['div-title']}>Loja</div>
                        <SelectPill
                            id="select-picker-pill"
                            placeholder="Selecione uma opção"
                            type="select"
                            name="loja"
                            filter={false}
                            searchable={false}
                            cleanable={false}
                            value={selectMode}
                            data={selectOptions}
                            onSelect={(value: any) => {
                                setSelectMode(value);
                            }}
                        />

                        <FilterSeparator style={{ marginInline: '12px' }} />

                        {selectMode === 'STORE' && (
                            <CheckPicker
                                id="select-picker-pill"
                                style={{ maxWidth: '300px' }}
                                cleanable
                                searchable
                                placeholder={getPlaceholder()}
                                type="select"
                                name="loja"
                                filter={false}
                                value={filtersArea?.selectedFilters.storesId}
                                data={filtersArea.list.stores}
                                onClose={() => {
                                    listStores();
                                    if (filtersArea.selectedFilters.storesId.length > 0) {
                                        getDemandForecastInputsValue(0);
                                    }
                                }}
                                onSelect={(value) => {
                                    handleSetFilter({
                                        name: 'storesId',
                                        filterValue: value,
                                        clearData: false,
                                    });
                                    handleSetActiveFilter({
                                        key: 'store',
                                        item: value,
                                    });

                                    setParam('storesId', value.join(','));
                                }}
                                onClean={() => {
                                    handleSetFilter({
                                        name: 'storesId',
                                        filterValue: [],
                                    });
                                    deleteParam('storesId');
                                }}
                                locale={{
                                    noResultsText: isListStoresLoading ? 'Carregando lojas...' : 'Nenhum item encontrado',
                                    searchPlaceholder: 'Pesquisar lojas',
                                }}
                                renderExtraFooter={() => (
                                    <div className={styles['render-extra-footer']}>
                                        <Checkbox
                                            block
                                            indeterminate={indeterminateStores}
                                            checked={isCheckedAllStores}
                                            onChange={(value: any, checked: boolean) => {
                                                const nextValue = checked ? filtersArea?.list.stores.map((item: { value: string }) => item.value) : [];

                                                handleSetFilter({
                                                    name: 'storesId',
                                                    filterValue: nextValue,
                                                    clearData: false,
                                                });
                                                handleSetActiveFilter({
                                                    key: 'store',
                                                    item: nextValue,
                                                });

                                                setParam('storesId', nextValue.join(','));
                                            }}
                                        >
                                            Selecionar todas as lojas
                                        </Checkbox>
                                    </div>
                                )}
                                onSearch={(search) => {
                                    debounce(() => {
                                        dispatch(setSearchStores(search));
                                    });
                                }}
                            />
                        )}
                    </div>

                    <div className={styles['filter-selection-wrapper']}>
                        <div className={styles['div-title']}>Período</div>
                        {/* @ts-ignore */}
                        <DateRangePickerPill
                            block
                            cleanable
                            classNameValue={filtersArea.selectedFilters.dateRange.length ? 'date-range-has-value' : ''}
                            renderValue={(value: Date[]) => (
                                <>
                                    <span>{format(value[0], 'dd/MM/yyyy')}</span>
                                    {' a '}
                                    <span>{format(value[1], 'dd/MM/yyyy')}</span>
                                </>
                            )}
                            value={filtersArea.selectedFilters.dateRange}
                            onChange={handleDateRangePicker}
                            onOk={handleDateRangePicker}
                            onClean={handleClearDatePicker}
                            appearance="default"
                            placeholder="Selecione um período"
                            format="DD/MM/YYYY"
                            locale={dateLocale}
                            ranges={dateShortcutNextDays}
                            disabledDate={beforeToday()}
                            ref={refToDatePicker}
                        />
                    </div>
                </div>
                <Button className={styles['btn-clear-filters']} onClick={() => onCleanAll()}>
                    Limpar filtros
                </Button>
            </div>
        </main>
    );
};
