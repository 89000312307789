import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectPicker } from 'rsuite';
import { listClusters } from '../../../../../../../../components/FilterSection/services';
import { useDebounce } from '../../../../../../../../hooks';
import { patchSetupIpaStoresAndClustersDataById, SetupIpaStoresAndClustersRowData } from '../../../service';
import styles from './Cells.module.scss';
import * as renderExtra from './ClusterRenders';

type ClusterProps = CellContext<SetupIpaStoresAndClustersRowData, unknown>;

const NO_CLUSTERS_MESSAGE = 'Nenhum cluster';

export const Cluster = ({
    row: { original: rowData },
    table: {
        options: { meta },
    },
}: ClusterProps) => {
    const debounce = useDebounce();
    const queryClient = useQueryClient();

    const [query, setQuery] = useState<string>('');

    const [isEnabledQuery, setIsEnabledQuery] = useState(false);

    const [value, setValue] = useState<string | null>(rowData.cluster);

    const handleSearch = useCallback(
        (query = '') => {
            debounce(() => setQuery(query));
        },
        [debounce],
    );

    const handleOpenSelectPicker = useCallback(() => {
        setIsEnabledQuery(true);
    }, []);

    const handleCloseSelectPicker = useCallback(() => {
        setIsEnabledQuery(false);
        handleSearch('');
    }, [handleSearch]);

    const handleClickCleanClusterValue = useCallback(() => setValue(null), []);

    const renderExtraFooterOptions = useMemo(() => {
        return renderExtra.renderExtraFooterOptions({
            handleClickCleanClusterValue,
            handleClickAddNewCluster: meta?.onCloseAddNewClusterModal,
        });
    }, [handleClickCleanClusterValue, meta]);

    const handleGetData = useCallback(async () => await listClusters(query), [query]);
    const { data, fetchStatus } = useQuery({
        queryKey: [`ipa.setup.clusters`, query],
        queryFn: handleGetData,
        enabled: isEnabledQuery,
    });

    const { mutateAsync: mutatePatchPost } = useMutation({
        mutationFn: async ({ storeId, clusterId }: { storeId: string; clusterId: string }) => {
            await patchSetupIpaStoresAndClustersDataById(storeId, clusterId);
            await queryClient.invalidateQueries({ queryKey: ['table-data'] });
            await queryClient.invalidateQueries({ queryKey: ['ipa.setup.clusters'] });
        },
    });

    const handleSelectCluster = useCallback(
        async (clusterId: string) => {
            const storeId = rowData.store.id;
            await mutatePatchPost({ storeId, clusterId });
            setValue(clusterId);
        },
        [mutatePatchPost]
    );

    const customLocale = {
        placeholder: 'Selecione uma opção',
        searchPlaceholder: 'Busque por um cluster',
        noResultsText: 'Nenhum resultado encontrado',
    };

    useEffect(() => {
        const styleElement = document.createElement('style');
        styleElement.innerHTML = `
            .rs-picker-search-bar::after {
                content: '';
                position: absolute;
                right: 200px !important;
                width: 16px;
                height: 16px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                pointer-events: none;
                padding-right: 12px;
                color: #378EF0 !important;
            }

            .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
                padding: 7px 22px !important;
            }
        `;
        document.head.appendChild(styleElement);

        return () => {
            document.head.removeChild(styleElement);
        };
    }, []);

    return (
        <SelectPicker
            className={styles['cluster']}
            value={value}
            data={data || []}
            onSearch={handleSearch}
            onOpen={handleOpenSelectPicker}
            onClose={handleCloseSelectPicker}
            locale={rowData.cluster ? {
                placeholder: rowData.cluster,
                searchPlaceholder: 'Busque por cluster',
                noResultsText: 'Nenhum resultado encontrado',
            } : customLocale}
            renderMenu={(menu) => {
                if (fetchStatus === 'fetching') return renderExtra.renderMenu(fetchStatus)(menu);
                if (!data?.length) return <div style={{ padding: '8px 12px' }}>{NO_CLUSTERS_MESSAGE}</div>;
                return menu;
            }}
            renderExtraFooter={renderExtra.renderExtraFooter(renderExtraFooterOptions)}
            onSelect={handleSelectCluster}
            cleanable={false}
        />
    );
};

