import classNames from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExport from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { LoadingSpinerLocal } from '../../../../../../../../components';
import { selectorDemandForecastOutputArea } from '../../../../../../../../reducers/previsaoDemanda';
import { ReloadStyleProps } from '../ResultScenarios';
import styles from './SeasonalityChart.module.scss';

HighchartsExporting(Highcharts);
HighchartsExport(Highcharts);

const HighchartsOptions = {
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
        exitFullscreen: 'Sair da tela cheia',
    },
};

Highcharts.setOptions(HighchartsOptions);

const colors = ['#0D66D0', '#FF756B', '#5AE47A', '#825DCB'];
export const SeasonalityChart = ({ styleProps }: { styleProps: ReloadStyleProps }) => {
    const chartRef = useRef(null);

    const outputArea = useSelector(selectorDemandForecastOutputArea);
    const { dailyStoreSalesChartData } = outputArea;

    if (!dailyStoreSalesChartData.data.length) {
        return (
            <div className={styles.container}>
                <div>
                    <p className={styles.title}>Gráfico de sazonalidade</p>
                    <p className={styles.subtitle}>Gráfico do preço simulado na venda para cada data no período selecionado</p>
                </div>
                <div className={classNames(styles.container, styles['container-loading'])}>
                    <LoadingSpinerLocal position="relative" size="md" height="100%" loading />
                </div>
            </div>
        );
    }

    const { title, subtitle, data: storeSales } = dailyStoreSalesChartData;

    const categories = storeSales[0].dates.map((item) => {
        return item.includes('Cenário') ? item : new Date(item).toLocaleDateString('pt-BR');
    });

    const amountSales = storeSales.reduce((acc, item) => {
        return acc + item.demands.reduce((accValue, itemValue) => accValue + itemValue, 0);
    }, 0);

    const series: Highcharts.SeriesOptions[] = storeSales.map((item, i) => {
        return {
            type: 'column',
            name: item.store || item.storeId,
            data: item.demands,
            color: i < colors.length ? colors[i] : null,
            amountSales,
        };
    });

    const defaultChartOption: Highcharts.Options = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
            align: 'left',
        },
        exporting: {
            enabled: false,
        },
        legend: {
            symbolWidth: 50,
            symbolRadius: 0,
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            min: 0,
            title: {
                text: 'VOLUME DE VENDAS',
            },
        },
        tooltip: {
            useHTML: true,

            formatter: function () {
                if (!this.points) {
                    return '';
                }

                const isScenarioMode = this.x.toString().includes('Cenário');
                const date = this.x;

                const initialState = `
                <p>
                    ${isScenarioMode ? 'Nome' : 'Data'}: <b>${date}</b>
                </p>
                <p>
                    Total vendas: <b> ${(this.points[0].series.userOptions as any)?.amountSales}</b>
                </p>
                `;

                return this?.points.reduce((s, point) => {
                    return `${s}<br/>
                        <span style=color:${point.color}
                            <b>■</b>
                        </span>${point.series.name}: <b>${point.y} un</b>`;
                }, initialState);
            },
            shared: true,
            headerFormat: ``,
        },
        plotOptions: {
            column: {
                stacking: 'normal',
            },
        },
        series: JSON.parse(JSON.stringify(series as Highcharts.SeriesOptionsType[])),
    };

    return (
        <div className={styles.container} style={{ ...styleProps }}>
            <div>
                <p className={styles.title}>{title} </p>
                <p className={styles.subtitle}>{subtitle}</p>
            </div>
            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={defaultChartOption} />
        </div>
    );
};
