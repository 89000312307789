import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import _ from 'lodash';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'rsuite';
import { DataItem } from '../../../../../@types/DataItem';
import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import { RootState } from '../../../../../@types/RootState';
import { InnerFilter } from '../../../../../components';
import { listFiltrosSalvos } from '../../../../../components/FilterSection/services';
import { useDebounce } from '../../../../../hooks';
import { openModal, RESET_SAVED_FILTERS, SET_SAVED_FILTER } from '../../../../../reducers/gerenciadorPrecos';
import { RESET_FILTER_CACHE, SET_FILTER_CACHE_BY_KEY } from '../../../../../reducers/gerenciadorPrecos/filters/filtersCache';
import { SET_FILTER_DATA_BY_KEY } from '../../../../../reducers/gerenciadorPrecos/filters/filtersData';
import { RESET_FILTERS_SEARCH } from '../../../../../reducers/gerenciadorPrecos/filters/filtersSearch';
import {
    ADD_SEGMENT_VALUE,
    RESET_FILTERS_VALUE,
    selectorFiltersValues,
    SET_FILTERS_VALUE_BY_KEY,
} from '../../../../../reducers/gerenciadorPrecos/filters/filtersValues';
import {
    CHANGE_QUICK_FILTER_VALUE,
    MarginFilterProps,
    RESET_QUICK_ACTION_FILTER,
    setMarginFilter,
    TOGGLE_QUICK_FILTER_ON,
} from '../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { checkIfIsLoading } from '../../../../../utils';
import { FilterItem, isEmptyFilters } from '../../utils';
import { Icon } from '../QuickActionFilter';
import styles from './SavedFilters.module.scss';

type Filter = {
    user: string | null;
    client: string | null;
    clientId: number | null;
    screen: string | null;
    filters: FilterItem;
    id: string;
    name: string;
    label: string;
    value: string;
};

const SavedFilters = ({ className, ...props }: React.HtmlHTMLAttributes<HTMLDivElement>) => {
    const dispatch = useDispatch();

    const debounce = useDebounce();

    const [search, setSearch] = useState('');

    const values = useSelector(selectorFiltersValues);

    const value = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.savedFilter.data.value;
    });

    const data = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.filters.data.savedFilters;
    });

    const filtersData = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.filters.data;
    });

    const onSearch = useCallback(
        (query: string) => {
            debounce(() => setSearch(query));
        },
        [debounce],
    );

    const onClose = useCallback(() => {
        onSearch('');
    }, [onSearch]);

    const onClean = useCallback(() => {
        dispatch(RESET_FILTERS_VALUE());
        dispatch(RESET_FILTERS_SEARCH());
        dispatch(RESET_FILTER_CACHE());
        dispatch(RESET_QUICK_ACTION_FILTER());
        dispatch(RESET_SAVED_FILTERS());
    }, [dispatch]);

    const onSuccesSavedFilters = useCallback(
        (data: DataItem[]) => {
            dispatch(SET_FILTER_DATA_BY_KEY({ key: 'savedFilters', data }));
        },
        [dispatch],
    );

    const { fetchStatus } = useQuery({
        queryKey: ['ipa.filter.savedFilters', search],
        queryFn: () => listFiltrosSalvos(search),
        initialData: [],
        onSuccess: onSuccesSavedFilters,
    });

    const handleSetFilter = useCallback(
        ({ key, value = [], cache = [] }: { key: GerenciadorPrecos.FilterKeys; value: string[]; cache: DataItem[] }) => {
            const uniqDataWithCache = _.uniqBy([...cache, ...(filtersData[key] ?? [])], 'value');
            dispatch(SET_FILTER_DATA_BY_KEY({ key, data: uniqDataWithCache }));
            dispatch(SET_FILTER_CACHE_BY_KEY({ key, cache }));
            dispatch(SET_FILTERS_VALUE_BY_KEY({ key, value }));
        },
        [dispatch, filtersData],
    );

    const onSelect = useCallback(
        ({ id, name, filters }: Filter) => {
            onClean();

            const data = {
                filterName: name,
                data: {
                    name,
                    label: name,
                    value: id,
                },
            };

            dispatch(SET_SAVED_FILTER({ data }));

            Object.keys(filters).forEach((key) => {
                if (filters[key as keyof typeof filters] === null) return;

                switch (key) {
                    case 'productIds': {
                        const cache =
                            filters.products?.map(({ id, description, ...filtro }) => ({
                                label: `${id} - ${description}`,
                                value: id,
                                ...filtro,
                            })) ?? [];
                        const value = filters.productIds ?? [];
                        handleSetFilter({ key: 'productIds', value, cache });
                        break;
                    }
                    case 'storeIds': {
                        const cache =
                            filters.stores?.map(({ id, description }) => ({
                                label: `${id} - ${description}`,
                                value: id,
                            })) ?? [];
                        const value = filters.storeIds ?? [];
                        handleSetFilter({ key: 'storeIds', value, cache });
                        break;
                    }
                    case 'clusters': {
                        const cache =
                            (filters as any)?.informationClusters?.map(({ id, description }: { id: string; description: string }) => {
                                const item = {
                                    label: `${id} - ${description}`,
                                    value: id,
                                };
                                return item;
                            }) ?? [];

                        const value = filters.clusters ?? [];
                        handleSetFilter({ key: 'clusters', value, cache });
                        break;
                    }
                    case 'productFamilyIds': {
                        const cache =
                            filters[key]?.map((item) => ({
                                label: item,
                                value: item,
                            })) ?? [];
                        const value = filters[key] ?? [];
                        handleSetFilter({
                            key: 'productFamilyIds',
                            value,
                            cache,
                        });
                        break;
                    }
                    case 'priceType': {
                        const cache =
                            filters[key]?.map((item) => ({
                                label: item,
                                value: item,
                            })) ?? [];
                        const value = filters[key] ?? [];
                        handleSetFilter({ key: 'priceType', value, cache });
                        break;
                    }
                    case 'supplier': {
                        const cache =
                            filters[key]?.map((item) => ({
                                label: item,
                                value: item,
                            })) ?? [];
                        const value = filters.supplier ?? [];
                        handleSetFilter({ key: 'supplier', value, cache });
                        break;
                    }
                    case 'productBrand': {
                        const cache =
                            filters[key]?.map((item) => ({
                                label: item,
                                value: item,
                            })) ?? [];
                        const value = filters.productBrand ?? [];
                        handleSetFilter({ key: 'productBrand', value, cache });
                        break;
                    }
                    case 'sensibilities': {
                        const value =
                            filters.sensibilities?.map((value) => ({
                                type: 'infoPriceSensibility',
                                value,
                            })) ?? [];
                        dispatch(ADD_SEGMENT_VALUE(value));
                        break;
                    }
                    case 'exclusive': {
                        const value =
                            filters.exclusive?.map((value) => ({
                                type: 'sensibilityType',
                                value,
                            })) ?? [];
                        dispatch(ADD_SEGMENT_VALUE(value));
                        break;
                    }
                    case 'curveAbcLocal': {
                        const value =
                            filters.curveAbcLocal?.map((value) => ({
                                type: 'infoPriceAbcClass',
                                value,
                            })) ?? [];
                        dispatch(ADD_SEGMENT_VALUE(value));
                        break;
                    }
                    case 'curveAbcGlobal': {
                        const value =
                            filters.curveAbcGlobal?.map((value) => ({
                                type: 'infoPriceGlobalAbcClass',
                                value,
                            })) ?? [];
                        dispatch(ADD_SEGMENT_VALUE(value));
                        break;
                    }
                    case 'categoryLevel1':
                    case 'categoryLevel2':
                    case 'categoryLevel3':
                    case 'categoryLevel4':
                    case 'categoryLevel5':
                    case 'categoryLevel6':
                    case 'categoryLevel7': {
                        const cache =
                            filters[key]?.map((item) => ({
                                label: item,
                                value: item,
                            })) ?? [];
                        const value = filters[key] ?? [];
                        handleSetFilter({ key, value, cache });
                        break;
                    }
                    case 'changedPrices': {
                        if (filters.changedPrices) {
                            dispatch(TOGGLE_QUICK_FILTER_ON(key));
                        }
                        break;
                    }
                    case 'brokenLimit': {
                        dispatch(TOGGLE_QUICK_FILTER_ON(key));
                        dispatch(
                            CHANGE_QUICK_FILTER_VALUE({
                                name: 'brokenLimit',
                                value: filters[key],
                            }),
                        );
                        break;
                    }
                    case 'marginRange': {
                        const marginFilter = filters[key] as Omit<MarginFilterProps, 'isActive'>;
                        dispatch(TOGGLE_QUICK_FILTER_ON(key));
                        dispatch(setMarginFilter({ ...marginFilter, isActive: true }));
                        break;
                    }

                    default:
                        break;
                }
            });
        },
        [dispatch, onClean, handleSetFilter],
    );

    const ON_OPEN_MODAL = useCallback(
        (name: GerenciadorPrecos.Modals) => {
            dispatch(openModal({ name }));
        },
        [dispatch],
    );

    const isEmpty = useMemo(() => isEmptyFilters(values), [values]);

    return (
        <div className={cn(styles.wrapper, className)} {...props}>
            {!isEmpty && (
                <Button className={styles.button} onClick={() => ON_OPEN_MODAL('salvarFiltros')}>
                    <Icon icon="MdSave" />
                </Button>
            )}
            <InnerFilter
                data={data ?? []}
                value={value ?? []}
                type="select"
                placeholder="Filtros salvos"
                placement="bottomEnd"
                onClose={onClose}
                onSearch={onSearch}
                onClean={onClean}
                onSelect={(_v, item) => onSelect(item as Filter)}
                isLoading={checkIfIsLoading(fetchStatus)}
            />
            {!!value.length && (
                <Button className={styles.button} onClick={() => ON_OPEN_MODAL('excluirFiltro')}>
                    <Icon icon="MdDelete" />
                </Button>
            )}
        </div>
    );
};

export default memo(SavedFilters);
