import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert } from 'rsuite';
import {
    selectorDemandForecastDemandForecastSavedScenarios,
    selectorDemandForecastFiltersArea,
    selectorDemandForecastOutputArea,
    selectorDemandForecastOutputSavedSimulations,
    selectorDemandForecastSimulatorArea,
} from '../../../../../../reducers/previsaoDemanda';
import { handleLoadingButtonMain, setFilter } from '../../../../../../reducers/previsaoDemanda/filterArea';
import { Input, setInputList } from '../../../../../../reducers/previsaoDemanda/inputArea';
import {
    OutputProps,
    Query,
    Scenario,
    setDailyStoreSalesChartData,
    setOutputs,
    setRevenueDemandChartData,
    SimulationProps,
} from '../../../../../../reducers/previsaoDemanda/outputArea';
import { handleModal, setScenario } from '../../../../../../reducers/previsaoDemanda/outputSavedSimulations';
import { camelCaseToSnakeCase } from '../../../../../../utils/camelCaseToSnakeCase';
import { snakeCaseToCamelCase } from '../../../../../../utils/snakeCaseToCamelCase';
import { Input as InputType, Output, Scenario as ScenarioType, SimulationProps as SimulationPropsSnake } from '../@types/output';
import { DemandForecastDataDTO } from '../@types/scenario-payload';
import {
    demandForecastDeleteScenario,
    demandForecastDownloadScenario,
    demandForecastSaveScenario,
    getDailyStoreSalesChartData,
    getDemandForecastResult,
    getRevenueDemandChartData,
    processDailyStoreSalesChart,
    saveDailyStoreSalesChart,
    saveRevenueDemandChart,
} from '../services';

type InputProps = InputType & {
    value: number;
};

type ScenarioToCompareProps = {
    inputs: Input[];
    output: OutputProps[];
    query: Query;
    scenario: Scenario;
    simulations: SimulationProps[];
};

export const useHandleScenarios = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const history = useHistory();

    const outputSavedSimulations = useSelector(selectorDemandForecastOutputSavedSimulations);
    const demandForecastSavedScenarios = useSelector(selectorDemandForecastDemandForecastSavedScenarios);
    const filtersArea = useSelector(selectorDemandForecastFiltersArea);
    const outputArea = useSelector(selectorDemandForecastOutputArea);
    const simulatorArea = useSelector(selectorDemandForecastSimulatorArea);

    const { modal } = outputSavedSimulations;

    const disabled = !demandForecastSavedScenarios.calculations.hasSomeChecked;

    const disabledMainButton = !demandForecastSavedScenarios.calculations.isActiveCompareButton;

    const simulationsCount = demandForecastSavedScenarios.pagination.totalElements;
    const simulationsSelected = demandForecastSavedScenarios.scenariosChecked.length;

    const handleDeleteScenario = async () => {
        const ids = demandForecastSavedScenarios.scenariosChecked.map((scenario) => scenario.id);
        await demandForecastDeleteScenario(ids);

        showMessageAndCloseModal('Cenário excluído com sucesso!');
        queryClient.invalidateQueries(['saved-scenarios']);
    };

    const generateScenarioName = () => {
        const { username, date } = outputArea.scenario;
        const { productName, storeIds } = outputArea.query;

        if (!productName || !storeIds.length) return { title: '', subtitle: '' };

        const storeName = filtersArea.list.stores.find((s) => s.store_id === storeIds[0])?.label || '';

        const savedAt = new Date(date ? `${date}T00:00:00.175` : 0);

        const oldTitle = outputArea.scenario?.id && outputArea.scenario?.scenarioName;

        const title = oldTitle || `${productName} - ${storeName}`;
        const subtitle = `Cenário salvo por ${username}, ${format(savedAt, 'dd/MM/yyyy')}`;

        return {
            title,
            subtitle,
        };
    };

    const payloadToSaveOrUpdateScenario = (isUpdate = false) => {
        const inputs = camelCaseToSnakeCase<Input[], InputProps[]>(simulatorArea.inputs);

        const { query, output, simulation } = camelCaseToSnakeCase(outputArea);

        const scenario = camelCaseToSnakeCase<Scenario, ScenarioType>(outputSavedSimulations.scenario);

        const queryMap = {
            ...query,
            product_name: filtersArea.activeFilter.productName,
        };

        const inputMap = inputs.map((input) => {
            return {
                ...input,
                base_price: input.value,
            };
        });

        const scenarioMap = {
            ...scenario,
            id: isUpdate ? scenario.id : null,
        };

        return {
            query: queryMap,
            input: inputMap,
            output,
            scenario: scenarioMap,
            simulations: simulation,
        };
    };

    const mappedDemandForecastData = (isUpdate = false) => {
        const { input, query, output, scenario, simulations } = payloadToSaveOrUpdateScenario(isUpdate);

        const demandForecast: DemandForecastDataDTO[] = input.map((demand) => {
            const newPrice = demand.new_price.toString();
            const outputOfCurrentPrice = output.find((o: Output) => o.new_price_scenario.price.toString() === newPrice);
            const simulationsOfCurrentPrice = simulations.find((s: SimulationPropsSnake) => s.new_price === newPrice)?.price_simulations;

            return {
                input: {
                    base_price: demand.base_price,
                    margin: demand.margin,
                    new_price: demand.new_price,
                    competitiveness: demand.competitiveness,
                },
                output: outputOfCurrentPrice,
                simulations: simulationsOfCurrentPrice,
            };
        });

        return {
            query,
            scenario,
            demandForecast,
            input,
        };
    };

    const handleSaveOrUpdateScenario = async (isUpdate = false) => {
        const { query, scenario, demandForecast, input } = mappedDemandForecastData(isUpdate);

        const response = await demandForecastSaveScenario({
            query,
            scenario,
            demandForecastDataDTO: demandForecast,
        });

        const dailyStoreSales = outputArea.dailyStoreSalesChartData.data.map((d) => {
            return {
                dates: d.dates,
                demands: d.demands,
                store: d.store,
                store_id: d.storeId,
                new_price: query.newPrice,
            };
        });

        const revenueDemand = outputArea.revenueDemandChartData.map((r) => {
            return {
                price: r.price,
                demand: r.demand,
                revenue: r.revenue,
                input_price: r.inputPrice,
            };
        });

        // const profitRevenue = outputArea.profitRevenueChartData.map((r) => {
        //     return {
        //         price: r.price,
        //         revenue: r.revenue,
        //         profit: r.profit,
        //         optimum_balanced_price: r.optimumBalancedPrice,
        //         optimum_profit_price: r.optimumProfitPrice,
        //         optimum_revenue_price: r.optimumRevenuePrice,
        //     };
        // });

        const simulationFamilyId = response.simulationFamilyId;
        const scenarioId = response.scenario.id;

        const mustSaveDailyStoreSales = input.length === 1; // Quando temos mais de 1 preço o gráfico é montado no front com base no output

        await Promise.all([
            mustSaveDailyStoreSales && saveDailyStoreSalesChart({ scenarioId: scenarioId, data: dailyStoreSales }),
            !mustSaveDailyStoreSales && processDailyStoreSalesChart(simulationFamilyId),
            saveRevenueDemandChart({ simulationFamilyId: simulationFamilyId, data: revenueDemand }),

            // TODO  /profit-revenue aguardando implementação
            // saveProfitRevenueChart({ simulationFamilyId: simulationFamilyId, data: profitRevenue }),
        ]);
    };

    const handleSaveScenario = async () => {
        try {
            handleSaveOrUpdateScenario();

            showMessageAndCloseModal('Cenário salvo com sucesso!');
        } catch (error) {
            Alert.error('Erro ao salvar cenário');
        }
    };

    const handleUpdateScenario = async () => {
        try {
            handleSaveOrUpdateScenario(true);

            showMessageAndCloseModal('Cenário salvo com sucesso!');
        } catch (error) {
            Alert.error('Erro ao salvar cenário');
        }
    };

    const handleDownloadScenario = async () => {
        const inputs = camelCaseToSnakeCase<Input[], InputProps[]>(simulatorArea.inputs);

        const { query, output, simulation, scenario } = camelCaseToSnakeCase(outputArea);

        const queryMap = {
            ...query,
            product_name: filtersArea.activeFilter.productName,
        };

        const inputMap = inputs.map((input) => {
            return {
                ...input,
                base_price: input.value,
            };
        });

        const data = [
            {
                input: inputMap,
                output,
                query: queryMap,
                scenario,
                simulations: simulation,
            },
        ];

        const response = await demandForecastDownloadScenario(data);

        if (response) {
            downloadScenario(response, scenario.scenario_name);
        }
    };

    const handleDownloadSelectedScenarios = async () => {
        const scenarios = demandForecastSavedScenarios.savedScenarios.list.filter((s) => s.checked);
        const data = scenarios.map((scen) => {
            const input = camelCaseToSnakeCase<Input, InputProps>(scen.input);

            const { query, output, simulations, scenario } = camelCaseToSnakeCase(scen);

            const queryMap = {
                ...query,
                product_name: filtersArea.activeFilter.productName,
            };

            const inputMap = {
                competitiveness: input.competitiveness || null,
                new_price: input.new_price,
                margin: input.margin,
                base_price: input.value || input.base_price || null,
            };

            return {
                input: [inputMap],
                output: [output],
                query: queryMap,
                scenario,
                simulations,
            };
        });

        const response = await demandForecastDownloadScenario(data);

        if (response) {
            downloadScenario(response, 'Simulações');
        }
    };

    const downloadScenario = (data: BlobPart, name: string) => {
        const blob = new Blob([data]);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = window.URL.createObjectURL(blob);
        hiddenElement.target = '_blank';
        hiddenElement.download = `${name}.csv`;
        hiddenElement.click();
        return blob;
    };

    const showMessageAndCloseModal = (message: string) => {
        closeAllModals();
        Alert.success(message);
    };

    const handleGetDemandForecastSimulate = async () => {
        const { selectedFilters } = filtersArea;
        const { inputs: inputsData } = simulatorArea;

        dispatch(handleLoadingButtonMain({ buttonName: 'getOutput', isLoading: true }));

        const inputsMapped = inputsData.map((input) => {
            return {
                base_price: input.value,
                competitiveness: input.competitiveness,
                margin: input.margin,
                new_price: String(input.newPrice).includes(',') ? String(input.newPrice).replace(',', '.') : input.newPrice,
            };
        });

        const outputData = {
            inputs: inputsMapped,
            query: {
                productId: selectedFilters.productId,
                storeIds: selectedFilters.storesId,
                fromDate: moment(selectedFilters.dateRange[0]).format('YYYY-MM-DD'),
                toDate: moment(selectedFilters.dateRange[1]).format('YYYY-MM-DD'),
            },
        };

        const output = await getDemandForecastResult(outputData);

        if (output) {
            const query = {
                ...output.query,
                product_name: filtersArea.activeFilter.productName,
            };

            dispatch(
                setOutputs({
                    simulation: output.simulations,
                    output: output.output,
                    query: query,
                    scenario: output.scenario,
                }),
            );

            dispatch(handleLoadingButtonMain({ buttonName: 'getOutput', isLoading: false }));

            getChartData(outputData, output, inputsData);
        }
    };

    const getChartData = async (outputData: any, output: any, inputsData: Input[]) => {
        const promiseChartRevenue = getRevenueDemandChartData(outputData);
        // const promiseChartProfit = getProfitRevenueChartData(outputData);

        function updateState(promise: Promise<any>, id: string, cb: (p: any) => void) {
            promise
                .then((result) => {
                    cb(result);
                })
                .catch((error) => {
                    console.error(`Promise ${id} rejected`);
                });
        }

        updateState(promiseChartRevenue, 'revenue', (chartRevenue) => dispatch(setRevenueDemandChartData(chartRevenue)));
        // updateState(promiseChartProfit, 'profit', (chartProfit) => dispatch(setProfitRevenueChartData(chartProfit)));
        // TODO  /profit-revenue aguardando implementação

        if (inputsData.length > 1) {
            const chartDailyData = {
                title: 'Gráfico comparativo entre cenários',
                subtitle: 'Gráfico do preço simulado na venda para cada cenário simulado',
                data: generateDailyStoreSalesChartDataWhenManyInputs(output.simulations),
            };
            dispatch(setDailyStoreSalesChartData(chartDailyData));
        } else {
            const promiseChartDaily = getDailyStoreSalesChartData(outputData);

            updateState(promiseChartDaily, 'daily', (chartDaily) => {
                const chartDailyData = {
                    title: 'Gráfico de sazonalidade',
                    subtitle: 'Gráfico do preço simulado na venda para cada data no período selecionado',
                    data: chartDaily,
                };
                dispatch(setDailyStoreSalesChartData(chartDailyData));
            });
        }
    };

    const generateDailyStoreSalesChartDataWhenManyInputs = (data: any[]) => {
        const list = snakeCaseToCamelCase(data);
        return list.reduce((chartData: any[], item: any, i: number) => {
            item.priceSimulations.forEach((ps: any) => {
                const scenarioLabel = `Cenário ${i + 1} - (${item.newPrice})`;
                const demand = ps.output.basePriceScenario.demandForecast;
                const existingStore = chartData.find((entry) => entry.storeId === ps.query.storeId);

                if (existingStore) {
                    existingStore.dates.push(scenarioLabel);
                    existingStore.demands.push(demand);
                } else {
                    chartData.push({
                        dates: [scenarioLabel],
                        demands: [demand],
                        store: ps.query.store,
                        storeId: ps.query.storeId,
                    });
                }
            });
            return chartData;
        }, []);
    };

    const handleShowModal = (id?: string) => {
        const { title } = generateScenarioName();
        dispatch(
            setScenario({
                scenarioName: `${title}`,
            }),
        );
        if (id) {
            dispatch(handleModal({ modalType: 'showModalUpdate', open: true }));
        } else {
            dispatch(handleModal({ modalType: 'showModalSave', open: true }));
        }
    };

    const handleShowConfirmActionModal = () => {
        dispatch(handleModal({ modalType: 'showModalConfirmAction', open: true }));
    };

    const closeAllModals = () => {
        dispatch(handleModal({ modalType: 'showModalSave', open: false }));
        dispatch(handleModal({ modalType: 'showModalUpdate', open: false }));
        dispatch(handleModal({ modalType: 'showModalConfirmAction', open: false }));
    };

    const handleOpenModalSave = () => {
        dispatch(handleModal({ modalType: 'showModalSave', open: false }));
        dispatch(handleModal({ modalType: 'showModalSave', open: true }));
    };

    const handleCompareScenarios = () => {
        const scenarios = demandForecastSavedScenarios.scenariosToCompare.reduce((acc, currScenario) => {
            const simulationMap = {
                newPrice: currScenario.input.newPrice.toString(),
                priceSimulations: currScenario.simulations,
            } as SimulationProps;

            const outputMap = {
                ...currScenario.output,
                metadata: {
                    productId: currScenario.query.productId,
                    productName: currScenario.query.productName,
                    scenarioId: currScenario.scenario?.id || '',
                    simulationFamilyId: currScenario?.simulationFamilyId || '',
                },
            };

            const inputMap = {
                ...currScenario.input,
                value: currScenario.input.newPrice,
            };

            acc['inputs'] = acc['inputs'] ? acc['inputs'].concat(inputMap) : [inputMap];
            acc['output'] = acc['output'] ? acc['output'].concat(outputMap) : [outputMap];
            acc['query'] = {
                ...currScenario.query,
                client: '',
                basePrice: 0,
            };
            acc['scenario'] = currScenario.scenario;
            acc['simulations'] = acc['simulations'] ? acc['simulations'].concat(simulationMap) : [simulationMap];
            return acc;
        }, {} as ScenarioToCompareProps);

        dispatch(
            setOutputs({
                simulation: scenarios.simulations,
                output: scenarios.output,
                query: scenarios.query,
                scenario: scenarios.scenario,
            }),
        );

        dispatch(setInputList({ inputs: scenarios.inputs }));
        dispatch(setScenario({ ...scenarios.scenario }));

        dispatch(setFilter({ name: 'productId', filterValue: scenarios.query.productId }));
        dispatch(setFilter({ name: 'storesId', filterValue: scenarios.query.storeIds }));

        const chartDailyData = {
            title: 'Gráfico comparativo entre cenários',
            subtitle: 'Gráfico do preço simulado na venda para cada cenário simulado',
            data: generateDailyStoreSalesChartDataWhenManyInputs(scenarios.simulations),
        };

        dispatch(setDailyStoreSalesChartData(chartDailyData));

        const dateFilter = [new Date(`${scenarios.query.fromDate}T00:00:00.175`), new Date(`${scenarios.query.toDate}T00:00:00.175`)];

        dispatch(setFilter({ name: 'dateRange', filterValue: dateFilter }));

        history.push('/ipa/simulacoes-analises/previsao-demanda-v2?scenario=compare');
    };

    return {
        handleGetDemandForecastSimulate,
        closeAllModals,
        handleShowModal,
        handleSaveScenario,
        handleShowConfirmActionModal,
        handleDeleteScenario,
        handleDownloadScenario,
        handleUpdateScenario,
        generateScenarioName,
        handleOpenModalSave,
        handleDownloadSelectedScenarios,
        handleCompareScenarios,
        props: {
            disabled,
            disabledMainButton,
            simulationsCount,
            simulationsSelected,
            modal,
        },
    };
};
