/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueries, useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Notification } from 'rsuite';
import { DataItem } from '../../../../../@types/DataItem';
import { useFilterData, useFilterSearch, useFilterValue } from '../../../../../components';
import { listClusters, listProducts, listSegment, listStores } from '../../../../../components/FilterSection/services';
import { useCount } from '../../../../../hooks';
import { Segment } from '../../../../../reducers/gerenciadorPrecos/segmentos';
import { getCattegories } from '../../../../../services/ProdutoService_ts';
import { checkIfIsLoading } from '../../../../../utils';
import History from '../../../../../utils/History';
import { getRegra, updateRegra } from '../../../RegrasPreco/Cadastro/services';
import { useEditRuleCompetitors } from '../RegrasDePreco/hooks';
import {
    SchemaNewRuleProps,
    SegmentsCurve,
    createSchemaNewRule,
    defaultFormValues,
    getNewRuleModel,
    parseEnumeratedSegments,
    parseNewRuleModel,
    segmentsCurve,
} from '../RegrasDePreco/lib';
import DATA, { StoreType } from '../RegrasDePreco/lib/data';

type Keys = Segment['field'] | SegmentsCurve | 'storeIds' | 'clusters';

const INITIAL_ROWS = 1;

const SchemaNewRule = createSchemaNewRule({ isEdited: true });

export const useEditarRegraDePreco = () => {
    const [data, { set: setData }] = useFilterData<Keys>();

    const [cache, { set: setCache, resetByKey: resetCacheByKey, reset: resetCache }] = useFilterData<Keys>();

    const [value, { set: setValue, resetByKey: resetValueByKey, reset: resetValue }] = useFilterValue<Keys>();

    const [search, { onSearch: handleSearch }] = useFilterSearch<Keys>();

    const [filterType, setFilterType] = React.useState<{
        storeIds?: StoreType;
        segment?: SegmentsCurve;
    }>({
        storeIds: 'all-stores',
    });

    const params = useParams<Record<'id', string>>();

    const { data: dataRegra } = useQuery({
        queryKey: ['regra-cadastrada', params.id],
        retry: false,
        enabled: !!params.id,
        queryFn: async () => {
            const res = await getRegra(params.id);
            return parseNewRuleModel(res);
        },
    });

    const form = useForm<SchemaNewRuleProps>({
        defaultValues: defaultFormValues,
        resolver: zodResolver(SchemaNewRule),
        ...(dataRegra && {
            values: dataRegra.form,
        }),
    });

    const [rows, { increment, decrement, set: setRows }] = useCount({
        initialData: INITIAL_ROWS,
    });

    const [selectedCattegories, setSelectedCattegories] = React.useState<Array<Keys>>([]);

    const { data: segments } = useQuery({
        queryKey: ['filters.cattegory.type'],
        queryFn: async (): Promise<Segment[]> => {
            const segments = await getCattegories();
            const numeratedSegments = parseEnumeratedSegments(segments);
            return [
                ...numeratedSegments,
                {
                    level: 0,
                    field: 'productIds',
                    value: 'productIds',
                    name: 'Produto',
                    label: 'Produto',
                },
            ];
        },
        initialData: [],
        retry: false,
    });

    const onSuccess = (key: Keys, data: DataItem[]) => {
        const uniqueData = _.uniqBy([...(cache[key] ?? []), ...(data ?? [])], 'value');
        setData(key, uniqueData);
    };

    const results = useQueries({
        queries: segments.map(({ value: key, level }) => ({
            queryKey: ['segment-data', key, search[key]],
            retry: false,
            queryFn: () => {
                return key === 'productIds' ? listProducts(search[key]) : listSegment(search[key], level);
            },
            onSuccess: (data: DataItem[]) => onSuccess(key, data),
            enabled: !!selectedCattegories.includes(key),
        })),
    });

    const alreadySelectedSegmentsData = segments.filter((segment) => {
        return selectedCattegories.includes(segment.value);
    });

    const avaliableCattegories = segments.filter((segment) => {
        return !selectedCattegories.includes(segment.value);
    });

    const isLastRow = rows === segments.length;

    const handleChangeCattegoryType = React.useCallback(
        (key: Keys, index: number) => {
            setSelectedCattegories((oldValue) => {
                if (value[oldValue[index]]?.length && oldValue[index] !== key) {
                    resetValueByKey(oldValue[index]);
                    resetCacheByKey(oldValue[index]);
                }
                const newValue = [...oldValue];
                newValue[index] = key;
                return _.uniq(newValue);
            });
        },
        [resetValueByKey, resetCacheByKey],
    );

    const handleSelect = React.useCallback(
        (key: Keys, value: string[]) => {
            const cache = data[key]?.filter((item) => {
                return value.includes(item.value);
            });
            setValue(key, value);
            setCache(key, cache ?? []);
        },
        [data, setValue, setCache],
    );

    const handleAddRow = React.useCallback(() => {
        if (isLastRow) return;
        increment();
    }, [isLastRow, increment]);

    const handleRemoveRow = React.useCallback(
        (index: number) => {
            if (rows === INITIAL_ROWS) return;
            const key = selectedCattegories[index];
            setSelectedCattegories((oldValue) => {
                const newValue = [...oldValue];
                newValue.splice(index, 1);
                return newValue;
            });
            resetValueByKey(key);
            resetCacheByKey(key);
            decrement();
        },
        [rows, selectedCattegories, resetValueByKey, resetCacheByKey, decrement],
    );

    const handleClean = React.useCallback(
        (key: Keys, index: number) => {
            if (value[key]?.length) {
                resetValueByKey(key);
                resetCacheByKey(key);
            }

            setSelectedCattegories((oldValue) => {
                const newValue = [...oldValue];
                newValue.splice(index, 1);
                return newValue;
            });
        },
        [value],
    );

    const getSegmentsModel = React.useCallback(() => {
        type SegmentsModel = {
            value: string;
            level: 'productId' | Keys;
        } & Record<string, unknown>;

        const Segments = new Set<SegmentsModel>([]);

        Object.entries(value).forEach(([key, value]) => {
            const _key = key as Keys;

            if (_key === 'productIds') {
                cache.productIds?.forEach((item) => {
                    Segments.add({
                        level: 'productId',
                        value: item.value,
                        description: item.description,
                    });
                });
            }

            if (_key.match(/categoryLevel/)) {
                value?.forEach((item) => {
                    Segments.add({
                        level: _key,
                        value: item,
                    });
                });
            }
        });
        return Array.from(Segments);
    }, [value, cache]);

    const competitors = useEditRuleCompetitors({
        storeId: value.storeIds ?? [],
        initialValues: dataRegra?.competitors.values,
    });

    const onSubmit = React.useCallback(
        async (formData: SchemaNewRuleProps) => {
            const result = SchemaNewRule.safeParse(formData);

            if (!result.success) {
                throw new Error('Dados inválidos');
            }

            const model = {
                ...getNewRuleModel(result.data),
                segments: getSegmentsModel(),
                storeIds: filterType.storeIds === 'individual-store' ? value.storeIds : [],
                clusters: value.clusters,
                ..._.pick(value, ['infoPriceSensibility', 'infoPriceAbcClass', 'infoPriceGlobalAbcClass', 'sensibilityType', 'abcClass']),
                ruleId: params.id,
                competitors: competitors.model,
            };

            const res = await updateRegra(params.id, model);

            if ([200, 201].includes(res.status)) {
                History.push('/ipa/regras-preco');
                resetValue();
                resetCache();
            } else {
                Notification.error({
                    title: 'Notificação',
                    duration: 6000,
                    description: 'Erro ao editar Regra, favor entrar em contato com o administrador.',
                });
            }
        },
        [competitors.model, filterType.storeIds, getSegmentsModel, params.id, resetCache, resetValue, value],
    );

    const handleSelectFilterType = React.useCallback(
        (key: keyof typeof filterType, value: string) => {
            setFilterType((oldValue) => {
                const isTheSameValue = oldValue[key] === value;

                if (oldValue[key] && !isTheSameValue) {
                    resetValueByKey(oldValue[key] as Keys);
                    resetCacheByKey(oldValue[key] as Keys);
                }

                return {
                    ...oldValue,
                    [key]: value,
                };
            });
        },
        [resetValueByKey, resetCacheByKey, setFilterType],
    );

    const cleanFilterType = React.useCallback(
        (key: keyof typeof filterType) => {
            setFilterType((oldValue) => {
                const newValue = { ...oldValue };
                delete newValue[key];
                return newValue;
            });
        },
        [setFilterType],
    );

    const { data: stores, fetchStatus: storeLoading } = useQuery({
        queryKey: ['storeIds', search?.storeIds],
        initialData: [],
        retry: false,
        queryFn: () => listStores(search?.storeIds),
        onSuccess: (data: DataItem[]) => onSuccess('storeIds', data),
    });

    const { data: clusters, fetchStatus: clustersLoading } = useQuery({
        queryKey: ['clusters', search?.clusters],
        initialData: [],
        retry: false,
        queryFn: () => listClusters(search?.clusters),
        onSuccess: (data: DataItem[]) => onSuccess('clusters', data),
        enabled: filterType.storeIds === 'clusters',
    });

    React.useEffect(() => {
        if (!dataRegra?.filters.storeIds) return;

        const { length } = dataRegra.filters.storeIds;

        if (length > 0) {
            handleSelectFilterType('storeIds', 'individual-store');
        }
    }, [dataRegra]);

    React.useEffect(() => {
        if (!dataRegra?.filters.clusters) return;

        const { length } = dataRegra.filters.clusters;

        if (length > 0) {
            console.log(dataRegra?.filters.clusters);
            handleSelectFilterType('storeIds', 'clusters');
        }
    }, [dataRegra]); // eslint-disable-line

    React.useEffect(() => {
        if (!dataRegra) return;

        const { segments, filters } = dataRegra;

        const values: Partial<Record<Keys, string[]>> = {};

        const caches: Partial<Record<Keys, DataItem[]>> = {};

        Object.entries(filters).forEach(([key, v]) => {
            const k = key as Keys;

            if (!v?.length) return;

            if (k === 'storeIds') {
                setValue(k, v);
            }

            if (k === 'clusters') {
                setValue(k, v);
            }

            if (segmentsCurve.includes(k as SegmentsCurve)) {
                setFilterType((oldValue) => ({
                    ...oldValue,
                    segment: k as SegmentsCurve,
                }));
                const cache = v.map((item) => ({
                    label: item,
                    value: item,
                }));
                setValue(k, v);
                setCache(k, cache);
            }
        });

        segments.forEach((segment) => {
            const k = String(segment.level) === 'productId' ? 'productIds' : (String(segment.level) as Keys);

            setSelectedCattegories((oldValue) => _.uniq([...oldValue, k]));

            values[k] = [...(values[k] ?? []), segment.value];

            setValue(k, values[k] ?? []);

            if (k.match(/productIds/)) {
                caches[k] = [
                    ...(caches[k] ?? []),
                    {
                        label: `${segment.value} - ${segment.description}` as string,
                        value: segment.value,
                        description: segment.description,
                    },
                ];
                setCache(k, caches[k] ?? []);
                return;
            }

            const cache = values[k]?.map((item) => ({
                label: item,
                value: item,
            }));

            caches[k] = cache;

            setCache(k, cache ?? []);
        });

        setRows(Object.keys(values).length);
    }, [dataRegra]);

    const isSegmentEmpty = (() => {
        for (const key in value) {
            if ((key.match(/categoryLevel/) || key.match(/productIds/)) && value[key as Keys]?.length) {
                return false;
            }
        }
        return true;
    })();

    const isStoreEmpty = filterType.storeIds === 'individual-store' ? !value.storeIds?.length : false;

    const isFilterValid = !isSegmentEmpty && !isStoreEmpty;

    return {
        form,
        onSubmit,
        rule: {
            status: dataRegra?.status,
        },
        filters: {
            isSegmentEmpty,
            isFilterValid,
            isStoreEmpty,
            cattegory: {
                rows,
                search,
                data,
                value,
                isLastRow,
                selectedCattegories,
                alreadySelectedSegmentsData,
                avaliableCattegories,
                handleClean,
                handleSearch,
                handleChangeCattegoryType,
                handleSelectCattegory: handleSelect,
                handleAddRow,
                handleRemoveRow,
                results,
            },
            store: {
                select: {
                    data: DATA.storeType,
                    value: filterType.storeIds,
                    onSelect(v: StoreType) {
                        resetValueByKey('storeIds');
                        resetCacheByKey('storeIds');
                        handleSelectFilterType('storeIds', v);
                        competitors.table.filters.reset();
                    },
                    onClean() {
                        cleanFilterType('storeIds');
                        resetValueByKey('storeIds');
                        resetCacheByKey('storeIds');
                    },
                },
                storeIds: {
                    data: stores ?? [],
                    value: value.storeIds ?? [],
                    onSearch(query: string) {
                        handleSearch('storeIds', query);
                    },
                    onSelect(value: string[]) {
                        handleSelect('storeIds', value);
                        competitors.table.filters.reset();
                    },
                    onClean() {
                        resetValueByKey('storeIds');
                        resetCacheByKey('storeIds');
                    },
                    onClose() {
                        if (!search.storeIds?.length) return;
                        handleSearch('storeIds', '');
                    },
                    isLoading: checkIfIsLoading(storeLoading),
                },
                clusters: {
                    data: clusters,
                    value: value.clusters ?? [],
                    isLoading: checkIfIsLoading(clustersLoading),
                    onSearch(query: string) {
                        handleSearch('clusters', query);
                    },
                    onSelect(value: string[]) {
                        handleSelect('clusters', value);
                        competitors.table.filters.reset();
                    },
                    onClean() {
                        resetValueByKey('clusters');
                        resetCacheByKey('clusters');
                    },
                    onClose() {
                        if (!search.clusters?.length) return;
                        handleSearch('clusters', '');
                    },
                },
            },
            segment: {
                select: {
                    data: DATA.segmentsType,
                    value: filterType?.segment,
                    onSelect(segment: SegmentsCurve) {
                        handleSelectFilterType('segment', segment);
                    },
                    onClean() {
                        if (filterType.segment) {
                            resetValueByKey(filterType.segment);
                            resetCacheByKey(filterType.segment);
                        }
                        setFilterType((oldValue) => {
                            const newValue = { ...oldValue };
                            delete newValue.segment;
                            return newValue;
                        });
                    },
                },
                check: {
                    value: filterType.segment ? value[filterType.segment] : [],
                    onSelect(key: SegmentsCurve, value: string[]) {
                        setValue(key, value);
                        setCache(
                            key,
                            data[key]?.filter((item) => {
                                return value.includes(item.value);
                            }) ?? [],
                        );
                    },
                    onClean(key: SegmentsCurve) {
                        resetValueByKey(key);
                        resetCacheByKey(key);
                    },
                },
            },
        },
        competitors,
    } as const;
};
