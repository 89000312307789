import React from 'react';

import { formatNumbersToPtBr } from '../../../../../../../../utils/FormatNumbers';
import { Indicator } from '../../../../../../../CalculadoraPrevisaoDemanda/Components';
import * as utils from '../../../../../../../CalculadoraPrevisaoDemanda/utils';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { selectorDemandForecastOutputArea } from '../../../../../../../../reducers/previsaoDemanda';
import { setInputIsMatchOpenedScenario } from '../../../../../../../../reducers/previsaoDemanda/inputArea';
import { BaseFields, OutputProps, setSimulationSelectedTable } from '../../../../../../../../reducers/previsaoDemanda/outputArea';
import { ForecastNumbers } from '../../../../../../../CalculadoraPrevisaoDemanda/Components/ForecastNumbers';
import styles from './Scenarios.module.scss';

export const ScenariosBigNumbers = () => {
    const outputArea = useSelector(selectorDemandForecastOutputArea);
    const { query, bestScenario } = outputArea;

    const dispatch = useDispatch();
    const history = useHistory();

    const openScenario = (scenario: OutputProps) => {
        const price = scenario.newPriceScenario?.price?.toString();
        const scenarioId = scenario.metadata?.scenarioId;

        if (!scenarioId) {
            dispatch(setSimulationSelectedTable({ query, output: [scenario] }));
            dispatch(setInputIsMatchOpenedScenario({ price: price }));
        }
        return history.push(`/ipa/simulacoes-analises/previsao-demanda-v2/${scenarioId}`);
    };

    const isBestScenario = (scenario: OutputProps, key: keyof BaseFields) => {
        if (!bestScenario) return;

        const valueType = scenario.newPriceScenario[key as keyof BaseFields] as string | number;

        if (typeof valueType === 'number') {
            return scenario.newPriceScenario[key] === bestScenario[key];
        }

        return scenario.newPriceScenario[key] === bestScenario[key];
    };

    return (
        <main className={styles.scenarios}>
            <div className={styles['scenarios-wrapper']}>
                {outputArea.output.map((scenario, index) => {
                    return (
                        <div key={index} className={styles['scenario-container']}>
                            <p>Cenário {index + 1}</p>
                            <div className={styles['product-name']}>
                                <span>{scenario.metadata?.productName || '--'}</span>
                            </div>
                            <div className={`${styles.border} ${isBestScenario(scenario, 'price') && styles['border-highlight']}`}>
                                <ForecastNumbers.Root title="preço">
                                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                        {utils.formatNumberToCurrency(scenario.newPriceScenario?.price, 2)}
                                    </ForecastNumbers.MainValue>
                                    <Indicator value={utils.formatIndicatorValue(scenario.scenariosVariation?.price)} />
                                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                                        {utils.formatNumberToCurrency(scenario.basePriceScenario?.price, 2)}
                                    </ForecastNumbers.SubValue>
                                </ForecastNumbers.Root>
                            </div>

                            <div className={`${styles.border} ${isBestScenario(scenario, 'competitiveness') && styles['border-highlight']}`}>
                                <ForecastNumbers.Root title="Competitividade">
                                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                        {scenario.newPriceScenario?.competitiveness
                                            ? utils.formatLocaleNumber(utils.formatIndicatorValue(scenario.newPriceScenario?.competitiveness), 1)
                                            : '--'}
                                        {scenario.newPriceScenario?.competitiveness ? '%' : null}
                                    </ForecastNumbers.MainValue>
                                    <Indicator type="pp" value={utils.formatIndicatorValue(scenario.scenariosVariation?.competitiveness)} />
                                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                                        {scenario.basePriceScenario?.competitiveness
                                            ? utils.formatLocaleNumber(utils.formatIndicatorValue(scenario.basePriceScenario?.competitiveness), 1)
                                            : '--'}
                                        {scenario.basePriceScenario?.competitiveness ? '%' : null}
                                    </ForecastNumbers.SubValue>
                                </ForecastNumbers.Root>
                            </div>

                            <div className={`${styles.border} ${isBestScenario(scenario, 'margin') && styles['border-highlight']}`}>
                                <ForecastNumbers.Root title="margem">
                                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                        {scenario.newPriceScenario?.margin
                                            ? utils.formatLocaleNumber(utils.formatIndicatorValue(scenario.newPriceScenario?.margin), 1)
                                            : 0}
                                        %
                                    </ForecastNumbers.MainValue>
                                    <Indicator type="pp" value={utils.formatIndicatorValue(scenario.scenariosVariation?.margin)} />
                                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                                        {scenario.basePriceScenario?.margin
                                            ? utils.formatLocaleNumber(utils.formatIndicatorValue(scenario.basePriceScenario?.margin), 1)
                                            : 0}
                                        %
                                    </ForecastNumbers.SubValue>
                                </ForecastNumbers.Root>
                            </div>

                            <div className={`${styles.border} ${isBestScenario(scenario, 'marginLevel1') && styles['border-highlight']}`}>
                                <ForecastNumbers.Root title="margem da categoria">
                                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                        {scenario.newPriceScenario?.marginLevel1
                                            ? utils.formatLocaleNumber(utils.formatIndicatorValue(scenario.newPriceScenario?.marginLevel1), 1)
                                            : 0}
                                        %
                                    </ForecastNumbers.MainValue>
                                    <Indicator type="pp" value={utils.formatIndicatorValue(scenario.scenariosVariation?.marginLevel1)} />
                                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                                        {scenario.basePriceScenario?.marginLevel1
                                            ? utils.formatLocaleNumber(utils.formatIndicatorValue(scenario.basePriceScenario?.marginLevel1), 1)
                                            : 0}
                                        %
                                    </ForecastNumbers.SubValue>
                                </ForecastNumbers.Root>
                            </div>

                            <div className={`${styles.border} ${isBestScenario(scenario, 'demandForecast') && styles['border-highlight']}`}>
                                <ForecastNumbers.Root title="PREVISÃO DE VENDAS (UN)">
                                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                        {formatNumbersToPtBr(scenario.newPriceScenario.demandForecast, 'decimal')}
                                    </ForecastNumbers.MainValue>
                                    <Indicator value={utils.formatIndicatorValue(scenario.scenariosVariation?.demandForecast)} />
                                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                                        {formatNumbersToPtBr(scenario.basePriceScenario?.demandForecast, 'decimal')}
                                    </ForecastNumbers.SubValue>
                                </ForecastNumbers.Root>
                            </div>

                            <div className={`${styles.border} ${isBestScenario(scenario, 'revenueForecast') && styles['border-highlight']}`}>
                                <ForecastNumbers.Root title="PREVISÃO DE RECEITA (R$)">
                                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                        {utils.formatNumberToCurrency(scenario.newPriceScenario.revenueForecast, 2)}
                                    </ForecastNumbers.MainValue>
                                    <Indicator value={utils.formatIndicatorValue(scenario.scenariosVariation?.revenueForecast)} />
                                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                                        {utils.formatNumberToCurrency(scenario.basePriceScenario?.revenueForecast, 2)}
                                    </ForecastNumbers.SubValue>
                                </ForecastNumbers.Root>
                            </div>

                            <div className={`${styles.border} ${isBestScenario(scenario, 'profitForecast') && styles['border-highlight']}`}>
                                <ForecastNumbers.Root title="PREVISÃO LUCRO (R$)">
                                    <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                        {utils.formatNumberToCurrency(scenario.newPriceScenario.profitForecast, 2)}
                                    </ForecastNumbers.MainValue>
                                    <Indicator value={utils.formatIndicatorValue(scenario.scenariosVariation?.profitForecast)} />
                                    <ForecastNumbers.SubValue margin="3px 0 0 0">
                                        {utils.formatNumberToCurrency(scenario.basePriceScenario?.profitForecast, 2)}
                                    </ForecastNumbers.SubValue>
                                </ForecastNumbers.Root>
                            </div>

                            <div className={styles['button-wrapper']}>
                                <Button className={styles['button']} appearance="primary" onClick={() => openScenario(scenario)}>
                                    Abrir Cenário
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </main>
    );
};
