import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';
import { DailyStoreSalesChartRequest, ProfitRevenueChartData, RevenueDemandChartRequest } from './@types/charts';
import { DownloadScenarioPayload, SaveScenarioPayload, SearchScenarioParams, SearchScenarioPayload } from './@types/scenario-payload';
import { InputsRequest, OutputsRequest } from './@types/service-types';

export const demandForecastSaveScenario = (data: SaveScenarioPayload) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts`,
            method: 'POST',
            expectToken: true,
            data: { ...data },
        }).then((resp) => resp.data),
    );

export const demandForecastSearchScenario = (params?: SearchScenarioParams, data?: SearchScenarioPayload) =>
    trackPromise(
        api({
            // eslint-disable-next-line max-len
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/search?page=${params?.page || 0}&size=${params?.size || 10}&sort=${params?.sort || ''},${params?.order || 'asc'}`,
            method: 'POST',
            expectToken: true,
            data: data ? { ...data } : {},
        }).then((resp) => resp.data),
    );

export const demandForecastDownloadScenario = (data: DownloadScenarioPayload[]) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/download`,
            method: 'POST',
            download: true,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'text/csv; charset=utf-8',
            },
            expectToken: true,
            data: data,
        }).then((resp) => resp.data),
    );

export const demandForecastGetScenarioById = (id: string) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/${id}`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const demandForecastDeleteScenarioById = (id: string) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/${id}`,
            method: 'DELETE',
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const demandForecastDeleteScenario = (ids: string[]) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/`,
            method: 'DELETE',
            expectToken: true,
            params: { id: ids.join(',') },
        }).then((resp) => resp.data),
    );

type params = {
    query?: string[];
};
export const getStores = ({ query }: params) => {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/store`,
            method: 'GET',
            expectToken: true,
            params: {
                size: 20,
                ...(query?.length && {
                    search: query.join(),
                }),
            },
        }).then((resp) => resp.data),
    );
};

export const getStoresById = (ids: string[]) => {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/store`,
            method: 'GET',
            expectToken: true,
            params: {
                size: 20,
                ...(ids?.length && {
                    ids: ids.join(),
                }),
            },
        }).then((resp) => resp.data),
    );
};
export const getDemandForecastResult = ({ query, inputs }: OutputsRequest) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/outputs`,
            method: 'POST',
            expectToken: true,
            data: {
                input: inputs,
                query: {
                    product_id: query.productId,
                    store_ids: query.storeIds,
                    from_date: query.fromDate,
                    to_date: query.toDate,
                },
            },
        }).then((resp) => resp.data),
    );

export const getDemandForecastInputs = ({ query }: InputsRequest) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/inputs`,
            method: 'POST',
            expectToken: true,
            data: {
                query: {
                    product_id: query.productId,
                    store_ids: query.storeIds,
                    from_date: query.fromDate,
                    to_date: query.toDate,
                    new_price: query.newPrice,
                },
            },
        }).then((resp) => resp.data),
    );

export const getRevenueDemandChartData = ({ query, inputs }: OutputsRequest) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/plots/revenue-demand`,
            method: 'POST',
            expectToken: true,
            data: {
                input: inputs,
                query: {
                    product_id: query.productId,
                    store_ids: query.storeIds,
                    from_date: query.fromDate,
                    to_date: query.toDate,
                },
            },
        }).then((resp) => resp.data),
    );

export const getProfitRevenueChartData = ({ query, inputs }: OutputsRequest) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/plots/profit-revenue`,
            method: 'POST',
            expectToken: true,
            data: {
                input: inputs,
                query: {
                    product_id: query.productId,
                    store_ids: query.storeIds,
                    from_date: query.fromDate,
                    to_date: query.toDate,
                },
            },
        }).then((resp) => resp.data),
    );

export const getDailyStoreSalesChartData = ({ query, inputs }: OutputsRequest) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/plots/daily-store-sales`,
            method: 'POST',
            expectToken: true,
            data: {
                input: inputs,
                query: {
                    product_id: query.productId,
                    store_ids: query.storeIds,
                    from_date: query.fromDate,
                    to_date: query.toDate,
                },
            },
        }).then((resp) => resp.data),
    );

export const saveDailyStoreSalesChart = ({ scenarioId, data }: { scenarioId: string; data: DailyStoreSalesChartRequest[] }) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/plots/daily-store-sales/save`,
            method: 'POST',
            expectToken: true,
            params: { scenario_id: scenarioId },
            data: data,
        }).then((resp) => resp.data),
    );

// Este end-point e usada para quando houver mais de um preco (multiplo cenario)
// salvar os cada cenario separadamente para que possamos fazer a edicao de cada um deles se necessario
export const processDailyStoreSalesChart = (simulationFamilyId: string) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/plots/daily-store-sales/process`,
            method: 'POST',
            expectToken: true,
            params: { simulation_family_id: simulationFamilyId },
        }).then((resp) => resp.data),
    );

export const getDailyStoreSalesChartById = (scenarioId: string) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/plots/daily-store-sales`,
            method: 'GET',
            expectToken: true,
            params: { scenario_id: scenarioId },
        }).then((resp) => resp.data),
    );

export const saveRevenueDemandChart = ({ simulationFamilyId, data }: { simulationFamilyId: string; data: RevenueDemandChartRequest[] }) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/plots/revenue-demand/save`,
            method: 'POST',
            expectToken: true,
            params: { simulation_family_id: simulationFamilyId },
            data: data,
        }).then((resp) => resp.data),
    );

export const getRevenueDemandChartById = (simulationFamilyId: string) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/plots/revenue-demand`,
            method: 'GET',
            expectToken: true,
            params: { simulation_family_id: simulationFamilyId },
        }).then((resp) => resp.data),
    );

export const saveProfitRevenueChart = ({ simulationFamilyId, data }: { simulationFamilyId: string; data: ProfitRevenueChartData[] }) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/plots/profit-revenue/save`,
            method: 'POST',
            expectToken: true,
            params: { simulation_family_id: simulationFamilyId },
            data: data,
        }).then((resp) => resp.data),
    );

export const getProfitRevenueChartById = (simulationFamilyId: string) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/plots/profit-revenue`,
            method: 'GET',
            expectToken: true,
            params: { simulation_family_id: simulationFamilyId },
        }).then((resp) => resp.data),
    );
