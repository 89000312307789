import { z } from 'zod';

export const setupBasicConfigSchema = z.object({
    id: z.string().optional(),
    pricing_preferences: z.enum(['STORE', 'CLUSTER']),
    cost_field: z.enum(['LAST_UNIT_NET_COST', 'MEAN_UNIT_NET_COST', 'MAX_UNIT_NET_COST', 'GLOBAL_MEAN_UNIT_NET_COST', 'GLOBAL_MAX_UNIT_NET_COST']),
});

export type SetupBasicConfigSchema = z.infer<typeof setupBasicConfigSchema>;

