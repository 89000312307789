import React from 'react';
import { useSelector } from 'react-redux';
import { selectorDemandForecastOutputArea } from '../../../../../../../reducers/previsaoDemanda';
import { Elasticity } from '../Charts/Elasticity';

import * as utils from '../../../../../../CalculadoraPrevisaoDemanda/utils';

import { formatNumbersToPtBr } from '../../../../../../../utils/FormatNumbers';
import { ForecastNumbers } from '../ForecastNumbers';
import { Indicator } from '../Indicator';
import styles from './BigNumbers.module.scss';

export const BigNumbers = () => {
    const outputArea = useSelector(selectorDemandForecastOutputArea, (prev, next) => prev.output[0].elasticity === next.output[0].elasticity);

    const { basePriceScenario, newPriceScenario, scenariosVariation } = outputArea.output[0];

    return (
        <div className={styles['first-chart-container']}>
            <div style={{ width: '320px', marginRight: '12px' }}>
                <Elasticity />
            </div>

            <div className={styles['forecast-container']}>
                <section>
                    <div>
                        <ForecastNumbers.Root title="preço">
                            <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                {utils.formatNumberToCurrency(newPriceScenario?.price, 2)}
                            </ForecastNumbers.MainValue>
                            <Indicator value={utils.formatIndicatorValue(scenariosVariation?.price)} />
                            <ForecastNumbers.SubValue margin="3px 0 0 0">{utils.formatNumberToCurrency(basePriceScenario?.price, 2)}</ForecastNumbers.SubValue>
                        </ForecastNumbers.Root>
                    </div>

                    <div>
                        <ForecastNumbers.Root title="Competitividade">
                            <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                {newPriceScenario?.competitiveness
                                    ? utils.formatLocaleNumber(utils.formatIndicatorValue(newPriceScenario?.competitiveness), 1)
                                    : '--'}
                                {newPriceScenario?.competitiveness ? '%' : null}
                            </ForecastNumbers.MainValue>
                            <Indicator type="pp" value={utils.formatIndicatorValue(scenariosVariation?.competitiveness)} />
                            <ForecastNumbers.SubValue margin="3px 0 0 0">
                                {basePriceScenario?.competitiveness
                                    ? utils.formatLocaleNumber(utils.formatIndicatorValue(basePriceScenario?.competitiveness), 1)
                                    : '--'}
                                {basePriceScenario?.competitiveness ? '%' : null}
                            </ForecastNumbers.SubValue>
                        </ForecastNumbers.Root>
                    </div>

                    <div>
                        <ForecastNumbers.Root title="margem produto">
                            <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                {newPriceScenario?.margin ? utils.formatLocaleNumber(utils.formatIndicatorValue(newPriceScenario?.margin), 1) : 0}%
                            </ForecastNumbers.MainValue>
                            <Indicator type="pp" value={utils.formatIndicatorValue(scenariosVariation?.margin)} />
                            <ForecastNumbers.SubValue margin="3px 0 0 0">
                                {basePriceScenario?.margin ? utils.formatLocaleNumber(utils.formatIndicatorValue(basePriceScenario?.margin), 1) : 0}%
                            </ForecastNumbers.SubValue>
                        </ForecastNumbers.Root>
                    </div>

                    <div>
                        <ForecastNumbers.Root title="margem setor" className={styles['margin-level']}>
                            <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                {newPriceScenario?.marginLevel1 ? utils.formatLocaleNumber(utils.formatIndicatorValue(newPriceScenario?.marginLevel1), 1) : 0}%
                            </ForecastNumbers.MainValue>
                            <Indicator type="pp" value={utils.formatIndicatorValue(scenariosVariation?.marginLevel1)} />
                            <ForecastNumbers.SubValue margin="3px 0 0 0">
                                {basePriceScenario?.marginLevel1 ? utils.formatLocaleNumber(utils.formatIndicatorValue(basePriceScenario?.marginLevel1), 1) : 0}
                                %
                            </ForecastNumbers.SubValue>
                        </ForecastNumbers.Root>
                    </div>

                    <div>
                        <ForecastNumbers.Root title="margem departamento" className={styles['margin-level']}>
                            <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                {newPriceScenario?.marginLevel2 ? utils.formatLocaleNumber(utils.formatIndicatorValue(newPriceScenario?.marginLevel2), 1) : 0}%
                            </ForecastNumbers.MainValue>
                            <Indicator type="pp" value={utils.formatIndicatorValue(scenariosVariation?.marginLevel2)} />
                            <ForecastNumbers.SubValue margin="3px 0 0 0">
                                {basePriceScenario?.marginLevel2 ? utils.formatLocaleNumber(utils.formatIndicatorValue(basePriceScenario?.marginLevel2), 1) : 0}
                                %
                            </ForecastNumbers.SubValue>
                        </ForecastNumbers.Root>
                    </div>
                </section>

                <section>
                    <div>
                        <ForecastNumbers.Root title="PREVISÃO DE VENDAS (UN)">
                            <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                {formatNumbersToPtBr(newPriceScenario.demandForecast, 'decimal')}
                            </ForecastNumbers.MainValue>
                            <Indicator value={utils.formatIndicatorValue(scenariosVariation?.demandForecast)} />
                            <ForecastNumbers.SubValue margin="3px 0 0 0">
                                {formatNumbersToPtBr(basePriceScenario?.demandForecast, 'decimal')}
                            </ForecastNumbers.SubValue>
                        </ForecastNumbers.Root>
                    </div>

                    <div>
                        <ForecastNumbers.Root title="PREVISÃO DE RECEITA (R$)">
                            <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                {utils.formatNumberToCurrency(newPriceScenario.revenueForecast, 2)}
                            </ForecastNumbers.MainValue>
                            <Indicator value={utils.formatIndicatorValue(scenariosVariation?.revenueForecast)} />
                            <ForecastNumbers.SubValue margin="3px 0 0 0">
                                {utils.formatNumberToCurrency(basePriceScenario?.revenueForecast, 2)}
                            </ForecastNumbers.SubValue>
                        </ForecastNumbers.Root>
                    </div>

                    <div>
                        <ForecastNumbers.Root title="PREVISÃO LUCRO (R$)">
                            <ForecastNumbers.MainValue margin="6px 0 3px 0">
                                {utils.formatNumberToCurrency(newPriceScenario.profitForecast, 2)}
                            </ForecastNumbers.MainValue>
                            <Indicator value={utils.formatIndicatorValue(scenariosVariation?.profitForecast)} />
                            <ForecastNumbers.SubValue margin="3px 0 0 0">
                                {utils.formatNumberToCurrency(basePriceScenario?.profitForecast, 2)}
                            </ForecastNumbers.SubValue>
                        </ForecastNumbers.Root>
                    </div>
                </section>
            </div>
        </div>
    );
};
