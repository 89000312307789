import moment from 'moment';
import React, { createRef, useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    Alert,
    Button,
    CheckPicker,
    Col,
    Container,
    Content,
    FlexboxGrid,
    Icon,
    IconButton,
    Input,
    InputGroup,
    Popover,
    Radio,
    RadioGroup,
    SelectPicker,
    Table,
    Whisper,
} from 'rsuite';
import { LoadingSpiner } from '../../../components/LoadingSpinner';
import ModalConfirmacao from '../../../components/ModalConfirmacao';
import { getLojas } from '../../../services/LojaService';
import { getAuthors, getClusters, getMechanisms, getRegras } from './services';

import lightbulbIcon from '../../../assets/icons/lightbulb.svg';
import { deleteRegra } from '../../../services/RegrasService';
import ModalConfiguracaoRegras from './Components/ModalConfiguracaoRegras';

const { Column, HeaderCell, Cell, Pagination } = Table;

const Speaker = (closeHighlight) => (
    <Popover className="highlight-popover">
        <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item colspan={1}>
                <img src={lightbulbIcon} alt="ícone de lâmpada" />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={18}>
                <p>Agora você pode escolher quais tipos de regras quer visualizar na lista.</p>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={1}>
                <Icon icon="close" onClick={closeHighlight} />
            </FlexboxGrid.Item>
        </FlexboxGrid>
    </Popover>
);

const ListaConfiguracoes = ({ modalConfigs, setModalConfigs }) => (
    <FlexboxGrid>
        <FlexboxGrid.Item colspan={8} style={{ marginTop: '8px' }}>
            <p className="list-title">Listagem de regras</p>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={16}>
            <RadioGroup name="configsList" value={modalConfigs} onChange={(value) => setModalConfigs(value)}>
                <Radio value="AUTO_PRICE">Listar somente regras automáticas</Radio>
                <Radio value="GENERIC_RULE">Listar todas as regras, incluindo regras geradas na precificação manual e na revisão</Radio>
            </RadioGroup>
        </FlexboxGrid.Item>
    </FlexboxGrid>
);

const RegrasPreco = ({ history }) => {
    let triggerRef = createRef();
    const ruleStatus = [
        {
            label: 'Ativa',
            value: 'ACTIVE',
        },
        {
            label: 'Expirada',
            value: 'EXPIRED',
        },
    ];
    const [data, setData] = useState({
        content: [],
    });
    const [authors, setAuthors] = useState([]);
    const [tiposRegra, setTiposRegra] = useState([]);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [name, setName] = useState('');
    const [mechanismType, setMechanismType] = useState('');
    const [description, setDescription] = useState('');
    const [showModalConfirmacaoExclusao, setShowModalConfirmacaoExclusao] = useState(false);
    const [showModalConfiguracao, setShowModalConfiguracao] = useState(false);
    const [regraToDelete, setRegraToDelete] = useState(null);
    const [criador, setCriador] = useState({
        cache: [],
        value: [],
    });
    const [tipoDescricao, setTipoDescricao] = useState('S');
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [lojas, setLojas] = useState([]);
    const [lojasImmutable, setLojasImmutable] = useState([]);
    const [lojasSelecionadas, setLojasSelecionadas] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [clustersImmutable, setClustersImmutable] = useState([]);
    const [clustersSelecionados, setClustersSelecionados] = useState([]);
    const [status, setStatus] = useState('');
    const [sort, setSort] = useState({
        type: 'recurrence.expirationDate',
        orderBy: 'asc',
    });
    const [regrasConfig, setRegrasConfig] = useState('');
    const [modalConfigs, setModalConfigs] = useState(localStorage.getItem('configuracaoRegra') || 'AUTO_PRICE');
    const [showHighlight, setShowHighlight] = useState(true);

    const getModelFiltros = () => ({
        name: name || null,
        mechanismType: mechanismType || null,
        author: criador.value ? criador.value : null,
        productDescription: tipoDescricao === 'P' && description ? description : null,
        category: tipoDescricao === 'S' && description ? description : null,
        page: page || null,
        size: size || null,
        searchStoreIds: lojasSelecionadas || null,
        searchCluster: clustersSelecionados || null,
        ruleStatus: status || null,
        sort: sort?.type && sort?.orderBy ? `${sort.type},${sort.orderBy}` : null,
        type: regrasConfig || localStorage.getItem('configuracaoRegra') || null,
    });

    const searchRegras = async () => {
        const query = getModelFiltros();

        const response = await getRegras(query);
        if (response) {
            setData(response);
        }
    };

    const searchCriador = async (query) => {
        const params = {
            author: query || null,
            type: localStorage.getItem('configuracaoRegra') || null,
        };

        const response = await getAuthors(params);
        if (response) {
            setAuthors(
                response.content
                    .map((item) => ({
                        value: item,
                        label: item,
                    }))
                    .concat(criador.cache.filter((itemCache) => response.content.findIndex((itemLista) => itemLista === itemCache.label) === -1)),
            );
        }
    };

    const getMechanismTypes = async () => {
        const response = await getMechanisms();
        if (response) {
            setTiposRegra(
                response
                    .map((item) => ({
                        value: item,
                        label: item,
                    }))
                    .filter((item) => (regrasConfig === 'AUTO_PRICE' ? item.value !== 'MANUAL' : item.value === 'MANUAL')),
            );
        }
    };

    const handleChangeFiltroCriador = (value) => {
        setCriador((prevState) => ({ ...prevState, value }));

        if (value === null) {
            setCriador((prevState) => ({ ...prevState, cache: [] }));
            searchCriador('');
        }
        if (value !== null && value.length !== criador.cache.length) {
            const newCacheProduto = criador.cache.filter((item) => value.indexOf(item.value) !== -1);

            setCriador((prevState) => ({
                ...prevState,
                cache: newCacheProduto,
            }));
        }
    };

    const handleSelectCriador = (value, item) => {
        const cacheCriador = criador.cache;
        cacheCriador.push(item);
        setCriador((prevState) => ({ ...prevState, cache: cacheCriador }));
    };

    const handleEdit = (regra) => {
        if (regra.type === 'manualPrice') {
            history.push(`/ipa/precificacao-produto/${regra.productId}/precificar?storeId=${regra.storeId}`);
        } else history.push(`/ipa/regras-preco/${regra.ruleId}`);
    };

    const handleDeleteRegra = (regra) => {
        setDeleteMessage('Você está tentando deletar uma regra. Você confirma essa ação?');
        setShowModalConfirmacaoExclusao(true);
        setRegraToDelete(regra);
    };

    const goNovaRegra = () => {
        history.push('/ipa/regras-preco/cadastro');
    };

    const closeExclusao = () => {
        setDeleteMessage('');
        setShowModalConfirmacaoExclusao(false);
        setRegraToDelete(null);
    };

    const closeConfiguracao = () => {
        setRegrasConfig(localStorage.getItem('configuracaoRegra'));
        setModalConfigs(localStorage.getItem('configuracaoRegra'));
        setShowModalConfiguracao(false);
    };

    const confirmExclusao = () => {
        deleteRegra(regraToDelete.ruleId).then(() => {
            Alert.success('Regra deletada com sucesso');
            closeExclusao();
            searchRegras();
        });
    };

    const confirmConfiguracao = () => {
        localStorage.setItem('configuracaoRegra', modalConfigs);
        setRegrasConfig(modalConfigs);
        setShowModalConfiguracao(false);
        searchCriador();
        searchRegras();
    };

    const getLojasCliente = async () => {
        const resp = await getLojas();
        setLojas(
            resp.map((item) => ({
                value: item.store_id,
                label: `${item.store_id} - ${item.store}`,
            })),
        );
        setLojasImmutable(
            resp.map((item) => ({
                value: item.store_id,
                label: `${item.store_id} - ${item.store}`,
            })),
        );
    };

    const getClustersCliente = async () => {
        const resp = await getClusters();
        const mappedClusters = resp.map((item) => ({
            value: item.value,
            label: item.label
        }));

        setClusters(mappedClusters);
        setClustersImmutable(mappedClusters);
    };

    const closeHighlight = () => {
        localStorage.setItem('showHighlight', false);
        setShowHighlight(false);
        triggerRef.hide();
    };

    useEffect(() => {
        if (!localStorage.getItem('configuracaoRegra')) {
            localStorage.setItem('configuracaoRegra', 'AUTO_PRICE');
            setRegrasConfig('AUTO_PRICE');
        } else {
            setRegrasConfig(localStorage.getItem('configuracaoRegra'));
        }
        if (!JSON.parse(localStorage.getItem('showHighlight')) && JSON.parse(localStorage.getItem('showHighlight')) !== false) {
            localStorage.setItem('showHighlight', true);
        }
        setShowHighlight(JSON.parse(localStorage.getItem('showHighlight')));
        searchCriador();
        getLojasCliente();
        getClustersCliente();
    }, []);

    useEffect(() => {
        if (regrasConfig) getMechanismTypes();
    }, [regrasConfig]);

    useEffect(() => {
        if (showHighlight && data?.content?.length) triggerRef.show();
    }, [showHighlight, data]);

    useEffect(() => {
        searchRegras();
    }, [page, size, criador, description, name, mechanismType, lojasSelecionadas, status, sort]);

    return (
        <Container className="main-container regra-preco-page">
            <Content className="regra-preco-filters">
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item>
                                <h4 className="regra-preco-title">Regras de Preços</h4>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <Button title="Nova regra" block onClick={goNovaRegra} appearance="primary">
                                    NOVA REGRA
                                </Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid>
                    <FlexboxGrid.Item componentClass={Col} sm={12} lg={8}>
                        <InputGroup>
                            <InputGroup.Button appearance={tipoDescricao === 'S' ? 'primary' : 'ghost'} onClick={() => setTipoDescricao('S')}>
                                Segmento
                            </InputGroup.Button>
                            <InputGroup.Button appearance={tipoDescricao === 'P' ? 'primary' : 'ghost'} onClick={() => setTipoDescricao('P')}>
                                Produto
                            </InputGroup.Button>
                            <Input placeholder="Descrição" name="descricaco" onChange={(value) => setDescription(value)} />
                        </InputGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item className="regra-nome" componentClass={Col} sm={12} lg={8}>
                        <InputGroup inside>
                            <Input placeholder="Nome da regra" name="nome" onChange={(value) => setName(value)} />
                            <InputGroup.Button>
                                <Icon icon="search" />
                            </InputGroup.Button>
                        </InputGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid>
                    <FlexboxGrid.Item componentClass={Col} sm={12} lg={4}>
                        <CheckPicker
                            className="inf-picker"
                            block
                            cleanable
                            searchable
                            data={lojas}
                            name="lojas"
                            placeholder="Lojas"
                            locale={{
                                noResultsText: 'Nenhum item encontrado',
                                searchPlaceholder: 'Busque a loja desejada',
                            }}
                            onSelect={(value) => setLojasSelecionadas(value)}
                            onClean={() => setLojasSelecionadas('')}
                            c
                            onClose={getLojasCliente}
                        />
                    </FlexboxGrid.Item>
                   {/*  <FlexboxGrid.Item componentClass={Col} sm={12} lg={4}>
                        <CheckPicker
                            className="inf-picker"
                            block
                            cleanable
                            searchable
                            data={clusters}
                            name="clusters"
                            placeholder="Clusters"
                            locale={{
                                noResultsText: 'Nenhum item encontrado',
                                searchPlaceholder: 'Busque o cluster desejado',
                            }}
                            onSelect={(value) => setClustersSelecionados(value)}
                            onClean={() => setClustersSelecionados('')}
                            onSearch={(search) => setClusters(clustersImmutable.filter((cluster) => cluster.label.includes(search)))}
                        />
                    </FlexboxGrid.Item> */}
                    <FlexboxGrid.Item componentClass={Col} sm={12} lg={4}>
                        <SelectPicker
                            className="inf-picker"
                            block
                            cleanable
                            searchable={false}
                            data={tiposRegra}
                            name="tipoRegra"
                            placeholder="Tipo da regra"
                            onSelect={(value) => setMechanismType(value)}
                            onClean={() => setMechanismType('')}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} sm={12} lg={3}>
                        <SelectPicker
                            className="inf-picker"
                            block
                            cleanable
                            searchable={false}
                            data={ruleStatus}
                            name="status"
                            placeholder="Status"
                            onSelect={(value) => setStatus(value)}
                            onClean={() => setStatus('')}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} sm={12} lg={6}>
                        <CheckPicker
                            className="inf-picker"
                            cleanable
                            searchable
                            data={authors}
                            name="author"
                            placeholder="Criador"
                            locale={{
                                noResultsText: 'Nenhum item encontrado',
                                searchPlaceholder: 'Busque o criador desejado',
                            }}
                            onSelect={handleSelectCriador}
                            onChange={(value) => handleChangeFiltroCriador(value)}
                            onSearch={(search) => searchCriador(search)}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} sm={24} lg={7}>
                        <FlexboxGrid justify="end">
                            <FlexboxGrid.Item>
                                {showHighlight ? (
                                    <Whisper
                                        placement="left"
                                        speaker={Speaker(closeHighlight)}
                                        triggerRef={(ref) => {
                                            triggerRef = ref;
                                        }}
                                    >
                                        <IconButton icon={<Icon icon="gear-circle" />} onClick={() => setShowModalConfiguracao(true)} />
                                    </Whisper>
                                ) : (
                                    <IconButton icon={<Icon icon="gear-circle" />} onClick={() => setShowModalConfiguracao(true)} />
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Content>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <LoadingSpiner size="md" />

                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={24}>
                                <Table
                                    data={data.content}
                                    autoHeight
                                    className="table"
                                    sortColumn={sort.type}
                                    sortType={sort.orderBy}
                                    onSortColumn={(sortColumn, sortType) =>
                                        setSort({
                                            type: sortColumn,
                                            orderBy: sortType,
                                        })
                                    }
                                >
                                    <Column align="center" width={75} sortable>
                                        <HeaderCell>Status</HeaderCell>
                                        <Cell dataKey="status">
                                            {(rowData) =>
                                                !rowData.ruleStatus ? (
                                                    '--'
                                                ) : (
                                                    <Icon
                                                        className={`rule-status-icon ${rowData.ruleStatus}`}
                                                        icon={
                                                            rowData.ruleStatus === 'ACTIVE'
                                                                ? 'check-circle-o'
                                                                : rowData.ruleStatus === 'EXPIRED'
                                                                  ? 'times-circle-o'
                                                                  : ''
                                                        }
                                                        size="lg"
                                                    />
                                                )
                                            }
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2} sortable>
                                        <HeaderCell>Nome da Regra</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>
                                    <Column flexGrow={3}>
                                        <HeaderCell>Produto ou categoria</HeaderCell>
                                        <Cell>
                                            {(rowData) => {
                                                const segmentos = rowData.segments
                                                    ? rowData.segments
                                                          .map((item) =>
                                                              item?.level === 'productId'
                                                                  ? item?.value + (item?.description ? ` - ${item?.description}` : '')
                                                                  : item?.value,
                                                          )
                                                          .join('; ')
                                                    : rowData.type === 'manualPrice'
                                                      ? rowData?.productId + (rowData?.description ? ` - ${rowData?.description}` : '')
                                                      : '--';
                                                return <span title={segmentos}>{segmentos}</span>;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2}>
                                        <HeaderCell>Loja ou cluster</HeaderCell>
                                        <Cell>
                                            {(rowData) => {
                                                if (rowData.storeIds && rowData.clusters) {
                                                    const lojascluster = [...rowData.storeIds, ...rowData.clusters];

                                                    return <span>{lojascluster?.length ? lojascluster.join('; ') : '--'}</span>;
                                                }
                                                return <span>--</span>;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2} sortable>
                                        <HeaderCell>Segmentação</HeaderCell>
                                        <Cell>
                                            {(rowData) => {
                                                const sensibility = {
                                                    SENSITIVE: 'Sensível',
                                                    NOT_SENSITIVE: 'Não sensível',
                                                    SUPER_SENSITIVE: 'Super sensível',
                                                };

                                                const segmentacao = [
                                                    ...(rowData.sensibilityType?.length ? rowData.sensibilityType : []),
                                                    ...(rowData.infoPriceSensibility?.length ? [sensibility[rowData.infoPriceSensibility]] : []),
                                                    ...(rowData.infoPriceAbcClass?.length ? [`Local ${rowData.infoPriceAbcClass}`] : []),
                                                    ...(rowData.infoPriceGlobalAbcClass ? [`Global ${rowData.infoPriceGlobalAbcClass}`] : []),
                                                    ...(rowData.abcClass ? rowData.abcClass : []),
                                                ];
                                                return <span title={segmentacao}>{segmentacao?.length ? segmentacao.join('; ') : '--'}</span>;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2} sortable>
                                        <HeaderCell>Tipo de Regra</HeaderCell>
                                        <Cell dataKey="mechanism.mechanismType">
                                            {(rowData) => {
                                                const tipoRegra = [...(rowData.mechanism.mechanismType?.length ? rowData.mechanism.mechanismType : [])];
                                                return <span title={tipoRegra}>{tipoRegra}</span>;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column width={125} sortable>
                                        <HeaderCell>Multiplicador</HeaderCell>
                                        <Cell dataKey="mechanism.factor">
                                            {(rowData) =>
                                                rowData.type === 'manualPrice' ? (
                                                    '--'
                                                ) : rowData?.mechanism?.factor ? (
                                                    <CurrencyFormat
                                                        value={rowData.mechanism.factor * 100 || 0}
                                                        fixedDecimalScale
                                                        decimalScale={2}
                                                        displayType="text"
                                                        suffix="%"
                                                        decimalSeparator=","
                                                    />
                                                ) : (
                                                    '--'
                                                )
                                            }
                                        </Cell>
                                    </Column>
                                    <Column width={105} sortable>
                                        <HeaderCell>Frequência</HeaderCell>
                                        <Cell dataKey="recurrence.recurrenceType">
                                            {(rowData) => {
                                                let frequencia = '--';
                                                if (rowData.recurrence?.recurrenceType === 'DAILY') {
                                                    frequencia = 'DIÁRIA';
                                                } else if (rowData.recurrence?.recurrenceType === 'WEEKLY') {
                                                    frequencia = 'SEMANAL';
                                                } else if (rowData.recurrence?.recurrenceType === 'MONTHLY') {
                                                    frequencia = 'MENSAL';
                                                }

                                                return <span>{frequencia}</span>;
                                            }}
                                        </Cell>
                                    </Column>
                                    <Column flexGrow={2} sortable>
                                        <HeaderCell>Criador</HeaderCell>
                                        <Cell dataKey="author" />
                                    </Column>
                                    <Column flexGrow={2} sortable>
                                        <HeaderCell>Data final</HeaderCell>
                                        <Cell dataKey="recurrence.expirationDate">
                                            {(rowData) =>
                                                rowData.recurrence.expirationDate ? moment(rowData.recurrence.expirationDate).format('DD/MM/YYYY') : '--'
                                            }
                                        </Cell>
                                    </Column>
                                    <Column width={120} fixed="right">
                                        <HeaderCell />

                                        <Cell className="table__edit-button">
                                            {(rowData) => (
                                                <span className="mg-5">
                                                    <IconButton title="Editar" onClick={() => handleEdit(rowData)} icon={<Icon icon="edit2" />} size="md" />
                                                    <IconButton
                                                        title="Deletar"
                                                        onClick={() => handleDeleteRegra(rowData)}
                                                        size="md"
                                                        icon={<Icon icon="trash2" />}
                                                    />
                                                </span>
                                            )}
                                        </Cell>
                                    </Column>
                                </Table>
                                <Pagination
                                    lengthMenu={[
                                        {
                                            value: 20,
                                            label: 20,
                                        },
                                        {
                                            value: 40,
                                            label: 40,
                                        },
                                    ]}
                                    activePage={data.number + 1}
                                    displayLength={data.size}
                                    total={data.totalElements}
                                    onChangePage={(value) => setPage(value - 1)}
                                    onChangeLength={(value) => setSize(value)}
                                />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <ModalConfirmacao
                show={showModalConfirmacaoExclusao}
                onConfirm={confirmExclusao}
                onCancel={closeExclusao}
                title="Deletar regra"
                message={deleteMessage}
                confirmButton="Confirmar"
                cancelButton="Cancelar"
            />
            <ModalConfiguracaoRegras
                show={showModalConfiguracao}
                onConfirm={confirmConfiguracao}
                onCancel={closeConfiguracao}
                title="Configurações"
                subtitle="Escolha quais regras você quer ver na lista de regras de preço."
                message={ListaConfiguracoes({ modalConfigs, setModalConfigs })}
                confirmButton="Salvar"
                cancelButton="Cancelar"
            />
        </Container>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegrasPreco));
