import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../@types/RootState';
import { ICompetitorPriceSources } from '../../pages/IPA/Entities';

type Operators = 'BETWEEN' | 'HIGHEST' | 'LOWEST';
type BrokenLimit = {
    isActive: boolean;
    value: boolean;
};

type ChangedPrices = {
    isActive: boolean;
};

type CompetitorPrices = {
    isActive: boolean;
};

type IsProductMaster = {
    isActive: boolean;
};

type MinDateCpi = {
    isActive: boolean;
    value: number;
    competitorPriceSources: ICompetitorPriceSources[];
};

export type MarginFilterProps = {
    isActive: boolean;
    value: number;
    minValue: number;
    maxValue: number;
    operator: Operators;
    priceTypeFilter: 'SUGGESTED' | 'CURRENT' | null;
};

export type priceVariationFilterProps = {
    isActive: boolean;
    value: number;
    minValue: number;
    maxValue: number;
    operator: Operators;
    valueFilterType: 'POSITIVE' | 'NEGATIVE' | null;
};

export type PriceManagerQuickActionFilter = {
    brokenLimit: BrokenLimit;
    changedPrices: ChangedPrices;
    competitorPrices: CompetitorPrices;
    minDateCompetitorPrice: MinDateCpi;
    isProductMaster: IsProductMaster;
    marginRange: MarginFilterProps;
    priceVariationRange: priceVariationFilterProps;
};

export type QuickActionFilterLimitsKeys = keyof PriceManagerQuickActionFilter;

const initialState: PriceManagerQuickActionFilter = {
    brokenLimit: {
        isActive: false,
        value: false,
    },
    changedPrices: {
        isActive: false,
    },
    competitorPrices: {
        isActive: false,
    },
    minDateCompetitorPrice: {
        isActive: false,
        value: 7,
        competitorPriceSources: [],
    },
    isProductMaster: {
        isActive: false,
    },
    marginRange: {
        isActive: false,
        value: 0,
        minValue: 0,
        maxValue: 0,
        operator: 'HIGHEST',
        priceTypeFilter: 'SUGGESTED',
    },
    priceVariationRange: {
        isActive: false,
        value: 0,
        minValue: 0,
        maxValue: 0,
        operator: 'HIGHEST',
        valueFilterType: 'POSITIVE',
    },
};

const saveFilterSessionStorage = (key: string, state: MarginFilterProps | priceVariationFilterProps) => {
    sessionStorage.setItem(`gerenciador-${key}`, JSON.stringify(state));
};

const slice = createSlice({
    name: 'filtros-state',
    initialState,
    reducers: {
        TOGGLE_QUICK_FILTER_ON(state, action: PayloadAction<QuickActionFilterLimitsKeys>) {
            state[action.payload].isActive = true;
        },
        toggleQuickFilterOff(state, action: PayloadAction<{ name: QuickActionFilterLimitsKeys }>) {
            state[action.payload.name].isActive = false;
        },
        toggleQuickFilter(state, action: PayloadAction<{ name: QuickActionFilterLimitsKeys }>) {
            state[action.payload.name].isActive = !state[action.payload.name].isActive;
        },
        CHANGE_QUICK_FILTER_VALUE(
            state,
            action: PayloadAction<{
                name: 'brokenLimit' | 'minDateCompetitorPrice';
                value: boolean | number;
            }>,
        ) {
            state[action.payload.name].value = action.payload.value;
        },
        togglePriceCPI(state) {
            state.competitorPrices.isActive = !state.competitorPrices.isActive;
        },
        RESET_QUICK_ACTION_FILTER() {
            saveFilterSessionStorage('marginFilter', initialState.marginRange);
            return initialState;
        },
        toggleQuickActionFilter(state, action: PayloadAction<{ key: QuickActionFilterLimitsKeys }>) {
            state[action.payload.key].isActive = !state[action.payload.key].isActive;
        },
        setMinDateCompetitorPriceSource(state, action: PayloadAction<ICompetitorPriceSources[]>) {
            state.minDateCompetitorPrice.competitorPriceSources = action.payload;
        },
        setMarginFilter(state, action: PayloadAction<MarginFilterProps>) {
            state.marginRange = action.payload;
            saveFilterSessionStorage('margin-filter', action.payload);
        },
        setPriceVariationFilter(state, action: PayloadAction<priceVariationFilterProps>) {
            state.priceVariationRange = action.payload;
            saveFilterSessionStorage('price-variation-filter', action.payload);
        },
    },
});

const selectorQuickActionFilters = (state: RootState) => {
    return state.gerenciadorPrecosReducer.quickActionsFilters;
};

const {
    TOGGLE_QUICK_FILTER_ON,
    toggleQuickFilterOff,
    toggleQuickFilter,
    CHANGE_QUICK_FILTER_VALUE,
    RESET_QUICK_ACTION_FILTER,
    togglePriceCPI,
    toggleQuickActionFilter,
    setMinDateCompetitorPriceSource,
    setMarginFilter,
    setPriceVariationFilter,
} = slice.actions;

export {
    CHANGE_QUICK_FILTER_VALUE,
    RESET_QUICK_ACTION_FILTER,
    selectorQuickActionFilters,
    setMarginFilter,
    setMinDateCompetitorPriceSource,
    setPriceVariationFilter,
    TOGGLE_QUICK_FILTER_ON,
    togglePriceCPI,
    toggleQuickActionFilter,
    toggleQuickFilter,
    toggleQuickFilterOff,
};

export default slice.reducer;
