import classNames from 'classnames';
import React, { CSSProperties, useEffect } from 'react';
import { FaPercent } from 'react-icons/fa';
import { ButtonGroup, Dropdown, Input, InputGroup, Radio, RadioGroup } from 'rsuite';
import { MarginFilterProps } from '../../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import styles from './FilterMargin.module.scss';
import { MdKeyboardArrowDown } from './Icon/assets';
import { IconButton } from './IconButton';

const OPTIONS = [
    {
        id: 1,
        label: 'Preço sugerido',
        value: 'SUGGESTED',
    },
    {
        id: 2,
        label: 'Preço vigente',
        value: 'CURRENT',
    },
];

const SELECTOR = [
    { label: 'Maior ou igual', value: 'HIGHEST' },
    { label: 'Menor', value: 'LOWEST' },
    { label: 'Entre', value: 'BETWEEN' },
];
type FilterMarginProps = {
    icon?: React.ReactNode;
    unitLabel?: string;
    menuStyle: CSSProperties;
    skin?: 'neutral' | 'primary' | 'error';
    defaultValue?: string;
    placeholder?: string;
    state: MarginFilterProps;
    onClose: () => void;
    onClick?: (event: React.SyntheticEvent<Element, Event>) => void;
    onChangeValue: (paramName: keyof MarginFilterProps, value: any) => void;
    onSelect?: (eventKey: any, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const FilterMargin = ({ icon, menuStyle, placeholder, skin = 'neutral', onClick, onClose, state, onChangeValue }: FilterMarginProps) => {
    const [menuStyleState, setMenuStyleState] = React.useState(menuStyle);
    const [open, setOpen] = React.useState(false);
    const [isNegative, setIsNegative] = React.useState(false);

    const inputValueRef = React.useRef<HTMLInputElement>(null);
    const inputMinValueRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!open) return;
        setIsNegative(false);
        if (state.operator === 'BETWEEN') {
            inputMinValueRef.current?.focus();
            inputMinValueRef.current?.select();
        } else {
            inputValueRef.current?.focus();
            inputValueRef.current?.select();
        }
    }, [state.operator, open]);

    const handleChangeValue = (paramName: keyof MarginFilterProps, value: string | number) => {
        let inputValue = value.toString();

        inputValue = inputValue.replace(/[^0-9.-]/g, '');

        if (inputValue.includes('-')) {
            setIsNegative(true);
            inputValue = '-' + inputValue.replace(/-/g, '');
        }

        const parts = inputValue.split('.');
        if (parts.length > 2) {
            inputValue = parts[0] + '.' + parts[1];
        }

        const numericPart = isNegative ? inputValue.slice(1) : inputValue;

        if (numericPart.length === 0) {
            inputValue = isNegative ? '-0.0' : '0.0';
        } else if (numericPart.length === 1) {
            inputValue = isNegative ? `-0.${numericPart}` : `0.${numericPart}`;
        } else {
            inputValue = isNegative ? `-${numericPart.slice(0, -1)}.${numericPart.slice(-1)}` : `${numericPart.slice(0, -1)}.${numericPart.slice(-1)}`;
        }

        onChangeValue(paramName, parseFloat(inputValue));
    };

    const handleKeyDown = (paramName: keyof MarginFilterProps, e: any) => {
        const value = e.target.value;
        const isNegative = value.includes('-');
        const isValueZero = parseFloat(e.target.value) === 0;
        const isDeletingValue = e.key === 'Backspace' || e.key === 'Delete';

        if (!isDeletingValue) return;

        const isEqualsValues = state[paramName] === parseFloat(value.replace(',', '.'));

        if (isDeletingValue && (!value || isEqualsValues)) {
            setIsNegative(false);
            onChangeValue(paramName, 0);
        }

        if (isDeletingValue && isValueZero && isNegative) {
            setIsNegative(false);
            onChangeValue(paramName, parseFloat(e.target.value?.replace('-', '')));
        }
    };

    return (
        <ButtonGroup className={classNames('quick-action-filter__button__container quick-action-filter__toggle', styles.container)}>
            <IconButton icon={icon} placeholder={placeholder} skin={skin} onClick={onClick} />

            <Dropdown
                activeKey="1"
                eventKey="1"
                menuStyle={menuStyleState}
                placement="bottomEnd"
                onClose={() => {
                    setOpen(false);
                    onClose();
                }}
                onOpen={() => {
                    setOpen(true);
                }}
                renderTitle={() => <IconButton icon="MdKeyboardArrowDown" className="quick-action-filter__toggle__arrow" skin={skin} />}
                className={classNames(styles['dropdown-container'], styles['main-container'])}
            >
                <Dropdown.Item panel>
                    <p className={styles.title}>Filtrar preços com margem</p>

                    <div className={styles['operator-container']}>
                        <Dropdown
                            onOpen={() => {
                                setMenuStyleState({ ...menuStyle, height: 220 });
                            }}
                            onClose={() => {
                                setMenuStyleState(menuStyle);
                            }}
                            className={styles['operator-content']}
                            title={SELECTOR.find((item) => item.value === state.operator)?.label}
                            value={state.operator}
                            renderTitle={(item) => {
                                return (
                                    <div className={styles['render-title']}>
                                        <p>{item}</p>
                                        <MdKeyboardArrowDown color="#959595" />
                                    </div>
                                );
                            }}
                        >
                            {SELECTOR.filter((item) => item.value !== state.operator)
                                .concat(SELECTOR.find((item) => item.value === state.operator) || { label: '', value: '' })
                                .map((item) => {
                                    return (
                                        <Dropdown.Item
                                            key={item.value}
                                            value={item.value}
                                            onSelect={() => {
                                                onChangeValue('operator', item.value);
                                            }}
                                        >
                                            {item.label}
                                        </Dropdown.Item>
                                    );
                                })
                                .reverse()}
                        </Dropdown>

                        {state.operator === 'BETWEEN' ? (
                            <>
                                <InputGroup style={{ maxWidth: 92 }}>
                                    <Input
                                        inputRef={inputMinValueRef}
                                        value={state.minValue?.toFixed(1).replace('.', ',')}
                                        onChange={(value) => handleChangeValue('minValue', value)}
                                        onKeyDown={(e: any) => handleKeyDown('minValue', e)}
                                    />
                                    <InputGroup.Addon>
                                        <FaPercent size={10} />
                                    </InputGroup.Addon>
                                </InputGroup>
                                <span>e</span>
                                <InputGroup style={{ maxWidth: 92 }}>
                                    <Input
                                        value={state.maxValue?.toFixed(1).replace('.', ',')}
                                        onChange={(value) => handleChangeValue('maxValue', value)}
                                        onKeyDown={(e: any) => handleKeyDown('maxValue', e)}
                                    />
                                    <InputGroup.Addon>
                                        <FaPercent size={10} />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </>
                        ) : (
                            <>
                                <span>a</span>
                                <InputGroup style={{ maxWidth: 92 }}>
                                    <Input
                                        inputRef={inputValueRef}
                                        value={state.value?.toFixed(1).replace('.', ',')}
                                        onChange={(value) => handleChangeValue('value', value)}
                                        onKeyDown={(e: any) => handleKeyDown('value', e)}
                                    />

                                    <InputGroup.Addon>
                                        <FaPercent size={10} />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </>
                        )}
                    </div>
                </Dropdown.Item>

                <Dropdown.Item panel>
                    <RadioGroup name="marginFilters" value={state.priceTypeFilter} onChange={(e) => onChangeValue('priceTypeFilter', e)}>
                        {OPTIONS.map((item) => {
                            return (
                                <Radio key={item.id} value={item.value}>
                                    {item.label}
                                </Radio>
                            );
                        })}
                    </RadioGroup>
                </Dropdown.Item>
            </Dropdown>
        </ButtonGroup>
    );
};
