import React, { useEffect, useMemo } from 'react';
import { MdAttachMoney } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import { MarginFilterProps, setMarginFilter } from '../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { BaseQuickActionFilterProps, QuickActionFilter } from '../QuickActionFilter';

const { FilterMargin } = QuickActionFilter;

const MENU_STYLE = { width: '380px', height: '180px' };

export const MarginFilter = () => {
    const dispatch = useDispatch();

    const marginFilterState = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.quickActionsFilters.marginRange;
    });

    const handleToggleFilter = () => {
        const margin = {
            ...marginFilterState,
            isActive: !marginFilterState.isActive,
        };
        dispatch(setMarginFilter(margin));
    };

    const handleCloseFilter = () => {
        const margin = {
            ...marginFilterState,
            isActive: true,
        };

        dispatch(setMarginFilter(margin));
    };

    const handleChange = (paramName: keyof MarginFilterProps, value: any) => {
        const margin = { ...marginFilterState, [paramName]: value };
        dispatch(setMarginFilter(margin));
    };

    const skin = useMemo(() => {
        let skin: BaseQuickActionFilterProps['skin'] = 'neutral';
        if (marginFilterState.isActive) {
            skin = 'primary';
        }
        return skin;
    }, [marginFilterState.isActive]);

    useEffect(() => {
        const marginFilterState = sessionStorage.getItem('gerenciador-margin-filter');

        if (marginFilterState) {
            const marginFilterParsed = JSON.parse(marginFilterState);
            dispatch(setMarginFilter(marginFilterParsed));
        }
    }, []);

    return (
        <FilterMargin
            placeholder="Filtrar por Margem"
            icon={<MdAttachMoney />}
            menuStyle={MENU_STYLE}
            skin={skin}
            onClick={handleToggleFilter}
            onChangeValue={handleChange}
            onClose={handleCloseFilter}
            state={marginFilterState}
        />
    );
};
