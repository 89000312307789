import QueryString from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { api } from '../../../../../lib';
import { CreateClusterParams } from './schemas';

export type SetupIpaStoresAndClustersParams = {
    size: number;
    page: number;
};

export type SetupIpaStoresAndClustersRowData = {
    id: string;
    store: { name: string; address: string, id: string };
    storeType: string;
    cluster: string;
};

export type SetupIpaStoresAndClustersResponse = {
    content: SetupIpaStoresAndClustersRowData[];
    totalElements: number;
};

export const getSetupIpaStoresAndClustersData = async (params?: SetupIpaStoresAndClustersParams): Promise<SetupIpaStoresAndClustersResponse> => {


    const response = await api.get<SetupIpaStoresAndClustersResponse>('/products/stores-clusters', {
        expectToken: true,
        params,
        paramsSerializer: (params) => {
            return QueryString.stringify(params, { arrayFormat: 'repeat' });
        },
    });

    return response.data;
};

export const patchSetupIpaStoresAndClustersDataById = async (
    storeId: string,
    clusterId: string
): Promise<void> => {
    const response = await api.patch(
        `/products/stores-clusters`,
        { storeId, clusterId },
        { expectToken: true }
    );
    return response.data;
};

export const createCluster = async (data: CreateClusterParams) => {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/clusters`,
            method: 'POST',
            data,
            expectToken: true,
        }).then((response) => response.data),
        '',
    );
}
