import { useMutation } from '@tanstack/react-query';
import React, { ComponentProps, lazy, Suspense, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Loader } from 'rsuite';
import { queryClient } from '../../../../../App';
import { Callout } from '../../../../../components';
import { postCreateCluster } from '../../../../../components/FilterSection/services';
import * as actions from '../../../../../reducers/reducerIPA/storesAndClusters/modals';
import { selectorIpaFormStoresAndClustersModals } from '../../../../../reducers/reducerIPA/storesAndClusters/modals';
import Layout from '../Layout';
import { AddClusterModal } from './components';
import styles from './SetupIpaStoresAndClusters.module.scss';

const LazyTable = lazy(() => import('./components').then((module) => ({ default: module.SetupIpaStoresAndClustersTable })));
const LazyClusterSection = lazy(() => import('./components').then((module) => ({ default: module.ClusterSection })));

const { Title, Subtitle, Section, Root } = Layout;

export type SetupIpaStoresAndClustersProps = ComponentProps<'div'>;

const SetupIpaStoresAndClusters = ({ className, ...props }: SetupIpaStoresAndClustersProps) => {
    const dispatch = useDispatch();

    const modals = useSelector(selectorIpaFormStoresAndClustersModals);

    const handleClickAddNewCluster = useCallback(() => {
        dispatch(actions.OPEN_MODAL('ADD_CLUSTER'));
    }, [dispatch]);

    const handleCloseAddNewClusterModal = useCallback(() => {
        dispatch(actions.CLOSE_MODAL('ADD_CLUSTER'));
    }, [dispatch]);

    const handlePostCreateClusterSuccess = useCallback(async () => {
        await queryClient.invalidateQueries(['ipa.setup.clusters']);
        handleCloseAddNewClusterModal();
        Alert.success('Cluster criado com sucesso');
    }, [handleCloseAddNewClusterModal]);

    const handlePostCreateClusterError = useCallback(() => {
        Alert.error('Erro ao criar cluster');
    }, []);

    const { mutateAsync: mutateAddPost } = useMutation({
        mutationFn: postCreateCluster,
        onSuccess: handlePostCreateClusterSuccess,
        onError: handlePostCreateClusterError,
    });

    return (
        <>
            <Root asChild>
                <div {...props}>
                    <Section.Root>
                        <Callout variant="warning" size="md" className={styles['setup-ipa-stores-and-clusters__callout']}>
                            As alterações são salvas automaticamente e serão aplicadas na próxima integração de dados.
                        </Callout>
                    </Section.Root>
                    <Section.Root>
                        <Section.Header>
                            <Title>Suas lojas</Title>
                            <Subtitle>Essas são as suas lojas. Indique um cluster para a loja</Subtitle>
                        </Section.Header>
                        <Section.Content>
                            <Suspense fallback={<Loader />}>
                                <LazyTable onCloseAddNewClusterModal={handleClickAddNewCluster} />
                            </Suspense>
                        </Section.Content>
                    </Section.Root>
                    <Section.Root>
                        <Section.Header>
                            <Title>Cluster de lojas</Title>
                            <Subtitle>Configure, edite e adicione novos clusters de sua lojas.</Subtitle>
                        </Section.Header>
                        <Section.Content>
                            <Suspense fallback={<Loader />}>
                                <LazyClusterSection onClickAddNewClusterModal={handleClickAddNewCluster} />
                            </Suspense>
                        </Section.Content>
                    </Section.Root>
                </div>
            </Root>
            <AddClusterModal
                show={modals.ADD_CLUSTER}
                onHide={handleCloseAddNewClusterModal}
                onCancel={handleCloseAddNewClusterModal}
                onSubmit={mutateAddPost}
            />
        </>
    );
};

export { SetupIpaStoresAndClusters };
