import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSearchParams } from '../../../../../../hooks/useSearchParams';
import { selectorDemandForecastFiltersArea, selectorDemandForecastSimulatorArea } from '../../../../../../reducers/previsaoDemanda';
import { clearSavedScenarios } from '../../../../../../reducers/previsaoDemanda/demandForecastSimulations';
import {
    ActiveFilter,
    FiltersNameProps,
    resetFilters,
    setActiveFilter,
    setFilter,
    setProducts,
    setStores,
} from '../../../../../../reducers/previsaoDemanda/filterArea';
import { clearInputData, clearInputDataAndResetList, setInputsValue } from '../../../../../../reducers/previsaoDemanda/inputArea';
import { clearOutputData } from '../../../../../../reducers/previsaoDemanda/outputArea';
import { searchFiltroProdutos } from '../../../../../../services/ProdutoService';
import { ProductList } from '../@types/product';
import { ProductDto, ProductResponse } from '../@types/product-response';
import { StoreDto } from '../@types/store-response';
import { getDemandForecastInputs, getStores, getStoresById } from '../services';

type HandleSetActiveFilterProp =
    | {
          key: 'product';
          item: ProductList;
      }
    | {
          key: 'store';
          item: string[];
      }
    | {
          key: 'date';
          item: Date[];
      };

export const useFilters = () => {
    const dispatch = useDispatch();
    const params = useParams() as Record<'id', string>;

    const { getParam, deleteParam } = useSearchParams();
    const isCompareScenario = getParam('scenario') === 'compare';

    const filtersArea = useSelector(selectorDemandForecastFiltersArea);

    const simulatorArea = useSelector(selectorDemandForecastSimulatorArea);

    const listProducts = async (query?: string) => {
        const response: ProductResponse = await searchFiltroProdutos({
            query: query,
        });
        if (response) {
            const products = response.data.map((item: ProductDto) => ({
                label: `${item.product_id} - ${item.description}`,
                value: item.product_id,
                gtin: item.gtin as string,
                description: item.description,
                productId: item.product_id,
            }));
            dispatch(setProducts({ products }));
            return products;
        }
    };

    const listStores = async (query?: string[]) => {
        const ids = getParam('storesId')?.split(',');

        const response: StoreDto[] = ids?.length ? await getStoresById(ids) : await getStores({ query: query });
        if (response) {
            const storesResponse = response?.map((item) => ({
                value: item.store_id,
                label: item.store,
                store_id: item.store_id,
                description: item.store,
            }));

            const { list, selectedFilters } = filtersArea;

            if (query?.length === 0 || !query) {
                dispatch(setStores({ stores: storesResponse }));

                return storesResponse;
            }

            const storesSelected = list.stores.filter((store) => selectedFilters.storesId.includes(store.store_id));

            dispatch(setStores({ stores: [...storesSelected, ...storesResponse] }));
            return storesResponse;
        }
    };

    const handleSetFilter = ({ name, filterValue, clearData = true }: { name: FiltersNameProps; filterValue: string | any | Date[]; clearData?: boolean }) => {
        dispatch(setFilter({ name, filterValue }));

        if (clearData) {
            dispatch(clearInputData());
            dispatch(clearOutputData());
        }
    };

    const handleSetActiveFilter = ({ key, item }: HandleSetActiveFilterProp) => {
        const currentFilters = filtersArea.activeFilter;
        const productFilter = {} as ActiveFilter;

        switch (key) {
            case 'product':
                productFilter.productName = item.description;
                productFilter.productLabel = item.label;
                break;
            case 'store': {
                const clusterAmount = null;

                const storeAmount = item?.length;

                const storeLabel = storeAmount === 1 ? 'loja selecionada' : 'lojas selecionadas';
                const clusterLabel = clusterAmount === 1 ? 'cluster selecionado' : 'clusters selecionados';

                const message = storeAmount ? `${storeAmount} ${storeLabel}` : `${clusterAmount} ${clusterLabel}`;

                const storeOrClusterLabel = `${message} > `;

                productFilter.storeOrClusterLabel = storeOrClusterLabel;
                break;
            }
            case 'date': {
                const dateSelectedStart = moment(item[0]).format('DD/MM/YYYY');
                const dateSelectedEnd = moment(item[1]).format('DD/MM/YYYY');
                const date = `${dateSelectedStart} a ${dateSelectedEnd}`;

                productFilter.date = date;
                break;
            }
        }

        dispatch(setActiveFilter({ activeFilter: { ...currentFilters, ...productFilter } }));
    };

    const clearFilters = () => {
        dispatch(resetFilters());
        dispatch(clearInputDataAndResetList());
        dispatch(clearOutputData());
        dispatch(clearSavedScenarios());
    };

    const sicronizeParams = () => {
        const productId = getParam('productId') || '';
        const storesId = getParam('storesId')?.split(',') || [];
        const filterDate = getParam('filterDate')?.split('-');
        const dateRangeValue = filterDate?.length ? [new Date(filterDate[0]), new Date(filterDate[1])] : [];

        const product = filtersArea.list.products?.find((item) => item.value === productId);

        if (product) {
            handleSetActiveFilter({
                key: 'product',
                item: product,
            });
        }

        if (storesId.length) {
            handleSetActiveFilter({
                key: 'store',
                item: storesId,
            });
        }

        if (dateRangeValue.length) {
            handleSetActiveFilter({
                key: 'date',
                item: dateRangeValue,
            });
        }

        dispatch(setFilter({ name: 'productId', filterValue: productId }));
        dispatch(setFilter({ name: 'storesId', filterValue: storesId?.filter((item) => item) }));
        dispatch(setFilter({ name: 'dateRange', filterValue: dateRangeValue }));
    };

    const handleClearDatePicker = () => {
        deleteParam('filterDate');
        handleSetFilter({
            name: 'dateRange',
            filterValue: [],
            clearData: false,
        });
    };

    const getDemandForecastInputsValue = async (index: number) => {
        if (!filtersArea.isCanGetInput) return;

        const originalPrice = simulatorArea.inputs[index]?.value;
        const newPrice = simulatorArea.inputs[index]?.newPrice;

        if (!filtersArea.selectedFilters.productId || filtersArea.selectedFilters.storesId.length === 0) return;

        if (newPrice && originalPrice === newPrice) return;

        const query = {
            productId: filtersArea.selectedFilters.productId,
            productName: filtersArea.activeFilter.productName,
            storeIds: filtersArea.selectedFilters.storesId,
            fromDate: filtersArea.selectedFilters.dateRange[0],
            toDate: filtersArea.selectedFilters.dateRange[1],
            newPrice: newPrice ? newPrice : null,
        };

        const { input } = await getDemandForecastInputs({
            query,
        });

        if (input) {
            const data = {
                competitiveness: input.competitiveness,
                margin: input.margin,
                value: newPrice ? simulatorArea.inputs[index].value : input.base_price,
                newPrice: newPrice || input.new_price || input.base_price,
            };

            dispatch(setInputsValue({ inputs: data, index }));
        }
    };

    const generateSelectedItemDetails = (productsSelected?: ProductList[] | null, item?: ProductList) => {
        const products = productsSelected || filtersArea.list.products;
        const productSelected = filtersArea.selectedFilters.productId;
        const dateRangeValue = filtersArea.selectedFilters.dateRange || [];

        const productName = item || products?.find((item) => item.value === productSelected);

        const clusterAmount = null;

        const storeAmount = filtersArea.selectedFilters.storesId.length;

        const storeLabel = storeAmount === 1 ? 'loja selecionada' : 'lojas selecionadas';
        const clusterLabel = clusterAmount === 1 ? 'cluster selecionado' : 'clusters selecionados';

        const message = storeAmount ? `${storeAmount} ${storeLabel}` : `${clusterAmount} ${clusterLabel}`;

        const storeOrClusterLabel = `${message} > `;
        const dateSelectedStart = moment(dateRangeValue[0]).format('DD/MM/YYYY');
        const dateSelectedEnd = moment(dateRangeValue[1]).format('DD/MM/YYYY');

        const data = {
            productName: productName ? productName.description : null,
            productLabel: productName ? productName.label : null,
            storeOrClusterLabel,
            date: `${dateSelectedStart} a ${dateSelectedEnd}`,
        };

        dispatch(setActiveFilter({ activeFilter: data }));
    };

    const { isLoading: isListStoresLoading } = useQuery({
        queryKey: ['demand-forecast-stores', filtersArea.search.stores],
        retry: true,
        keepPreviousData: true,
        queryFn: () => listStores(filtersArea.search.stores ? [filtersArea.search.stores] : []),
    });

    useEffect(() => {
        if (!params?.id && !isCompareScenario) {
            getDemandForecastInputsValue(0);
            sicronizeParams();
        }
    }, [params?.id, isCompareScenario, filtersArea.isCompleted]);

    return {
        isListStoresLoading,
        handleSetFilter,
        listProducts,
        listStores,
        clearFilters,
        handleClearDatePicker,
        getDemandForecastInputsValue,
        generateSelectedItemDetails,
        handleSetActiveFilter,
    };
};
